import PropTypes from 'prop-types';
// form
import {Controller, useFormContext} from 'react-hook-form';
// @mui
import {Autocomplete, Stack, Typography} from '@mui/material';
import React, {useEffect} from "react";
import AppTextField from "../input-fields/AppTextField";
import {useSelector} from "../../redux/store";
import {useDispatch} from "react-redux";
import {getRoles} from "../../redux/slices/role";
import useEnvironment from "../../hooks/useEnvironment";
import {filter, find} from "lodash";

// ----------------------------------------------------------------------

RHFRoles.propTypes = {
    name: PropTypes.string
};

export default function RHFRoles({name, priceName, ...other}) {
    const {control, setValue} = useFormContext();
    const dispatch = useDispatch();
    const {workspace, organization, environmentReady} = useEnvironment();

    const {
        roles,
        rolesCollected
    } = useSelector((state) => state.roles);

    useEffect(() => {
        if (environmentReady && workspace && organization) {
            dispatch(getRoles(workspace, organization))
        }
    }, [rolesCollected, dispatch, workspace, organization, environmentReady])

    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => (
                <Autocomplete
                    name={name}
                    fullWidth
                    {...field}
                    {...other}
                    options={filter(roles, {active: true}) || []}
                    onChange={(event, value) => {
                        if (Array.isArray(value)) {
                            setValue(name, value.map((v) => typeof v === "string" ? v : v.identifier))
                        } else {
                            typeof value === "string" ? setValue(name, value) : value?.identifier && setValue(name, value.identifier)
                        }
                    }}
                    autoHighlight
                    isOptionEqualToValue={(option, value) => (typeof value !== "string" && option.identifier === value.identifier) || option.identifier === value}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                            const newOption = find(roles, {identifier: option});
                            return newOption ? newOption.name : '';
                        }
                        return option.name || "";
                    }}
                    renderOption={(props, option) => (
                        <div {...props}>
                            <Stack style={{alignItems: "flex-start !important"}}>
                                <Typography color={"secondary.dark"}
                                            variant={'overline'}> {option.name}</Typography>
                                <Typography variant={'subtitle2'}> {option.description}</Typography>
                            </Stack>
                        </div>
                    )}
                    renderInput={(params) => (
                        <AppTextField
                            {...params}
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                            label={other.label || 'Roles'}
                        />
                    )}
                />
            )}
        />
    );
}
