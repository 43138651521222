import {createContext, useEffect, useState} from "react";
import useEnvironment from "../hooks/useEnvironment";
import useAuth from "../hooks/useAuth";
import axios from "../utils/axios";
import {capitalize, find} from "lodash";
import Loading from "../components/Loading";

const debug = false;

const initialPermissions = {
    initialized: false,
    loading: false,
    role: null,
    permissions: null,
};

export const PermissionsContext = createContext({
    permissions: initialPermissions,
    hasAccess: (arg) => {
        return false;
    },
}); // component props type

const PermissionsProvider = ({children, workspace, organization}) => {

    const [permissions, setPermissions] = useState(initialPermissions);

    const {environmentReady} = useEnvironment();

    const {user} = useAuth();

    useEffect(() => {
        if (environmentReady && workspace && organization && user && user?.permissions && capitalize(user.permissions[0].role) !== permissions?.role?.name && !permissions.loading && !permissions.initialized) {

            setPermissions({
                ...permissions,
                loading: true
            })
            if (debug) console.log("Get some permmissions")

            axios.get('/api/roles', {params: {organization, workspace, name: capitalize(user.permissions[0].role)}})
                .then((response) => {
                    const {data: {roles = []}} = response;
                    if (debug) console.log({roles})

                    if (roles[0]?.permissions?.length > 0) {
                        axios.get('/api/permissions', {params: {identifier: roles[0]?.permissions?.join(',') || ''}})
                            .then((response) => {
                                const {data: {permissions = []}} = response;
                                if (debug) console.log({roles, permissions})
                                setPermissions({
                                    role: roles[0],
                                    permissions,
                                    loading: false,
                                    initialized: true
                                });
                            })
                            .catch((error) => console.error(error))
                    } else {
                        setPermissions({
                            role: roles[0],
                            permissions: [],
                            loading: false,
                            initialized: true
                        });
                    }
                })
                .catch((error) => console.error(error))
        }
    }, [environmentReady, workspace, organization, user, permissions]);

    const hasAccess = (permissionIdentifier, assetIdentifier, roleIdentifier) => {
        const assetAccess = !!find(permissions.permissions, (permission) => permission?.ids?.some((id) => id === assetIdentifier));
        const permissionAccess = !!find(permissions.permissions, {identifier: permissionIdentifier});
        const roleAccess = roleIdentifier && permissions?.role?.identifier === roleIdentifier;
        if (debug) console.log('hasAccess')
        if (debug) {
            console.log('hasAccess', {
                permissions,
                permissionIdentifier,
                assetIdentifier,
                roleIdentifier,
                hasAccess: assetAccess || roleAccess || permissionAccess
            })
        }
        return assetAccess || roleAccess || permissionAccess;
    }

    return (
        <PermissionsContext.Provider
            value={{
                permissions,
                hasAccess
            }}
        >
            {children}
        </PermissionsContext.Provider>
    );
};

export default PermissionsProvider;

export function HasAccess({permission, asset, role, children}) {
    return (
        <PermissionsContext.Consumer>
            {({hasAccess}) => {
                if (hasAccess(permission, asset, role)) {
                    return children
                }
                return null;
            }}

        </PermissionsContext.Consumer>
    )
}

export function PermissionsLoading({message, messageVariant, children}) {
    return (
        <PermissionsContext.Consumer>
            {({permissions}) => {
                const {loading, initialized} = permissions;
                const showLoading = loading || !initialized;
                if (showLoading) {
                    return (
                        <Loading
                            message={message || "Loading user permissions"}
                            baseProperties={{
                                style: {
                                    backgroundColor: "transparent",
                                    background: "transparent"
                                }
                            }}
                            messageProperties={{
                                style: {
                                    backgroundColor: "transparent",
                                    background: "transparent"
                                }
                            }}
                            messageTypography={{
                                variant: messageVariant || "subtitle2"
                            }}
                            style={{backgroundColor: "transparent", background: "transparent"}}
                        />
                    )
                }
                return children;
            }}

        </PermissionsContext.Consumer>
    )
}
