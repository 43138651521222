import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    integrations: [],
    integrationsLoaded: false
};

const slice = createSlice({
    name: 'package',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET PACKAGES
        getIntegrationsSuccess(state, action) {
            state.isLoading = false;
            state.integrationsLoaded = true;
            state.integrations = action.payload;
        },

        // GET PACKAGES
        createIntegrationsSuccess(state, action) {
            state.isLoading = false;
            state.integrationsLoaded = true;
            state.integrations = {...state.integrations, ...action.payload};
        },

        // GET PACKAGES
        updateIntegrationsSuccess(state, action) {
            state.isLoading = false;
            state.integrationsLoaded = true;
            state.integrations = {...state.integrations, ...action.payload};
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getIntegrations(workspace, organization) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/integrations', {params: {workspace, organization}});
            dispatch(slice.actions.getIntegrationsSuccess(response.data.integrations[0]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createIntegration(integration) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put('/api/integrations', {integrations: [integration]});
            dispatch(slice.actions.createIntegrationsSuccess(response.data.integrations[0]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateIntegration(integration) {
    return async () => {
        console.log({integration})
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/api/integrations', {integrations: [integration]});
            dispatch(slice.actions.createIntegrationsSuccess(response.data.integrations[0]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

