import {useCallback, useEffect, useState} from 'react';
import {each, find} from 'lodash';
import axios from '../utils/axios';
import useEnvironment from "./useEnvironment";
import {navigations} from "../layouts/layout-parts/navigation";

const debug = process.env.REACT_APP_ENV === 'dev';
// ----------------------------------------------------------------------

export default function useNavigation() {

    const {environmentReady} = useEnvironment();

    const [localCatalog, setLocalCatalog] = useState(null)

    const [navigation, setNavigation] = useState([]);

    // const {alerts} = useSelector((state) => state.alerts);

    const buildNavigation = useCallback((navigationConfiguration, catalog) => {
        if (debug) console.log('buildNavigation', {navigationConfiguration, catalog})
        each(navigationConfiguration, (component, index) => {
            const asset = find(catalog, (asset) => asset.name?.toLowerCase() === component.label?.toLowerCase());
            if (debug) console.log('buildNavigation', {asset})
            navigationConfiguration[index].identifier = asset?.identifier || navigationConfiguration[index].identifier || '';
            each(component.items, (subName, subIndex) => {
                const func = find(asset?.functions, (asset) => asset.name.toLowerCase() === subName.name.toLowerCase());
                navigationConfiguration[index].items[subIndex].identifier = func?.identifier || navigationConfiguration[index].items[subIndex].identifier || '';
            });
        });
        if (debug) console.log('buildNavigation', {navigationConfiguration})
        setNavigation(navigationConfiguration);
    }, [])

    useEffect(() => {
        let navigationConfiguration = navigations
        // if (environmentReady && !localCatalog && alerts && buildNavigation) {
        if (environmentReady && !localCatalog && buildNavigation) {
            axios.get('/api/catalog/component?depth=1')
                .then((response) => {
                    const {data: {catalog}} = response;
                    setLocalCatalog(catalog)

                    buildNavigation(navigationConfiguration, catalog)
                })
                .catch((error) => {
                    console.error(error);
                });
            // } else if (alerts && environmentReady && localCatalog && buildNavigation) {
        } else if (environmentReady && localCatalog && buildNavigation) {
            buildNavigation(navigationConfiguration, localCatalog)
        }
        // }, [alerts, environmentReady, localCatalog, buildNavigation]);
    }, [environmentReady, localCatalog, buildNavigation]);

    return {
        navigations: navigation,
    };
}
