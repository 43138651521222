import PropTypes from 'prop-types';
// @mui
import {alpha, styled} from '@mui/material/styles';
import {Box, Typography} from '@mui/material';
//
import Logo from './Logo';
import ProgressBar from './ProgressBar';
import {motion} from "framer-motion";

// ----------------------------------------------------------------------


const BaseStyle = styled('div')(({theme}) => ({
    width: '100%',
    height: '100%',
    flexGrow: 1,
    minHeight: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
}));

const RootStyle = styled('div')(({theme}) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
}));


const MessageStyle = styled('div')(({theme}) => ({
    position: 'relative',
    marginTop: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

Loading.propTypes = {
    isDashboard: PropTypes.bool,
    message: PropTypes.string,
    baseProperties: PropTypes.object,
    messageProperties: PropTypes.object,
    messageTypography: PropTypes.object,
};

export default function Loading({
                                    isDashboard,
                                    message,
                                    baseProperties,
                                    messageProperties,
                                    messageTypography,
                                    ...other
                                }) {
    return (
        <>
            <ProgressBar/>

            {!isDashboard && (
                <BaseStyle {...baseProperties}>
                    <RootStyle {...other}>
                        <motion.div
                            initial={{rotateY: 0}}
                            animate={{rotateY: 360}}
                            transition={{
                                duration: 2,
                                ease: 'easeInOut',
                                repeatDelay: 1,
                                repeat: Infinity,
                            }}
                        >
                            <Logo disabledLink style={{
                                width: 66,
                                marginLeft: -15,
                                marginTop: -10
                            }}/>
                        </motion.div>

                        <Box
                            component={motion.div}
                            animate={{
                                scale: [1.2, 1, 1, 1.2, 1.2],
                                rotate: [270, 0, 0, 270, 270],
                                opacity: [0.25, 1, 1, 1, 0.25],
                                borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                            }}
                            transition={{ease: 'linear', duration: 3.2, repeat: Infinity}}
                            sx={{
                                width: 100,
                                height: 100,
                                borderRadius: '25%',
                                position: 'absolute',
                                border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
                            }}
                        />

                        <Box
                            component={motion.div}
                            animate={{
                                scale: [1, 1.2, 1.2, 1, 1],
                                rotate: [0, 270, 270, 0, 0],
                                opacity: [1, 0.25, 0.25, 0.25, 1],
                                borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                            }}
                            transition={{
                                ease: 'linear',
                                duration: 3.2,
                                repeat: Infinity,
                            }}
                            sx={{
                                width: 120,
                                height: 120,
                                borderRadius: '25%',
                                position: 'absolute',
                                border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
                            }}
                        />
                    </RootStyle>
                    <MessageStyle {...messageProperties}>
                        <Typography variant={'h4'}
                                    color={"text.secondary"} {...messageTypography}>{message}</Typography>
                    </MessageStyle>
                </BaseStyle>
            )}
        </>
    );
}
