import {useFormContext} from "react-hook-form";
import {Box, Card, Divider, Grid, InputAdornment, styled, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import FlexBox from "../../../components/flexbox/FlexBox";
import {H4, H5} from "../../../components/Typography";
import {
    RHFGoogleAddress,
    RHFPhoneField,
    RHFTextField,
    RHFUploadAvatar,
    RHFUploadCoverPhoto
} from "../../../components/hook-form";
import {SOCIAL_LINKS} from "../../../config";
import {fData} from "../../../utils/formatNumber";
import {useCallback} from "react";

// ------------------------------------------------------

const ContentWrapper = styled(Box)(({theme}) => ({
    zIndex: 1,
    marginTop: 150,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
        paddingLeft: 20,
        paddingRight: 20,
    },
}));

const CoverPicWrapper = styled(Box)(({theme}) => ({
    top: 0,
    left: 0,
    height: 175,
    maxHeight: 175,
    width: "100%",
    overflow: "hidden",
    position: "absolute",
    backgroundColor: theme.palette.background.default,
}));
// ------------------------------------------------------

const OrganizationInformation = () => {
    const {watch, setValue} = useFormContext();
    const values = watch();
    const {t} = useTranslation();

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];
            if (file) {
                setValue(
                    'organization.avatarFile',
                    file
                );
                setValue(
                    'organization.avatar',
                    URL.createObjectURL(file)
                );

            }
        },
        [setValue]
    );


    const handleBackDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];
            if (file) {
                setValue(
                    'organization.pictureFile',
                    file
                );
                setValue(
                    'organization.picture',
                    URL.createObjectURL(file)
                );

            }
        },
        [setValue]
    );
    return (
        <>
            <Card
                sx={{
                    padding: 3,
                    position: "relative",
                }}
            >
                <CoverPicWrapper style={{zIndex: 1}}>
                    <RHFUploadCoverPhoto
                        style={{maxHeight: 175}}
                        name="organization.picture"
                        accept="image/*"
                        maxSize={3145728}
                        onDrop={handleBackDrop}
                        value={values.organization.picture}
                        helperText={
                            <Typography
                                variant="caption"
                                sx={{
                                    mt: 2,
                                    mx: 'auto',
                                    display: 'block',
                                    textAlign: 'center',
                                    color: 'text.secondary',
                                }}
                            >
                                Allowed *.jpeg, *.jpg, *.png, *.gif
                                <br/> max size of {fData(3145728)}
                            </Typography>
                        }
                    />
                </CoverPicWrapper>

                <ContentWrapper>
                    <FlexBox justifyContent="center">
                        <Box style={{width: 250}}>
                            <RHFUploadAvatar
                                avatarProps={{style: {marginTop: -70}}}
                                name="organization.avatar"
                                accept="image/*"
                                maxSize={3145728}
                                onDrop={handleDrop}
                                value={values.organization.avatar}
                                helperText={
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            mt: 2,
                                            mx: 'auto',
                                            display: 'block',
                                            textAlign: 'center',
                                            color: 'text.secondary',
                                        }}
                                    >
                                        Allowed *.jpeg, *.jpg, *.png, *.gif
                                        <br/> max size of {fData(3145728)}
                                    </Typography>
                                }
                            />
                        </Box>
                    </FlexBox>

                    <Box mt={2}>
                        <H4 fontWeight={600} textAlign="center">
                            {values?.name}
                        </H4>
                    </Box>
                </ContentWrapper>
            </Card>

            <Card
                sx={{
                    mt: 3,
                }}
            >
                <H5 padding={3}>{t('Organization')}</H5>
                <Divider/>
                <Box margin={3}>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <RHFTextField
                                name="organization.name"
                                label="Name"
                            />
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <RHFTextField
                                fullWidth
                                name="organization.email"
                                label="Email"
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <RHFTextField
                                fullWidth
                                name="organization.supportEmail"
                                label="Support Email"
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <RHFTextField
                                fullWidth
                                name="organization.billingEmail"
                                label="Billing Email"
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item sm={12}>
                            <RHFPhoneField
                                fullWidth
                                user={values.organization}
                                name="organization.phoneNumber"
                                friendlyNameName={"organization.phoneFriendlyName"}
                                countryName={"organization.phoneCountry"}
                                countryCodeName={"organization.phoneCountryCode"}
                                label="Phone"
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item sm={12}>
                            <RHFGoogleAddress
                                fullWidth
                                friendlyNameName={"organization.addressFriendlyName"}
                                nameBase={"organization"}
                                name={"organization.address"}
                                label={"Address"}
                                user={values.organization}
                            />
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <RHFTextField
                                fullWidth
                                name={"organization.url"}
                                label={"Website"}
                                user={values}
                            />
                        </Grid>

                        {SOCIAL_LINKS.map((link) => (
                            <Grid key={link.value} item sm={6} xs={12}>
                                <RHFTextField
                                    name={`organization.${link.value}`}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{link.icon}</InputAdornment>,
                                    }}
                                />
                            </Grid>
                        ))}

                        <Grid item sm={12}>

                            <RHFTextField name="organization.about" multiline rows={4} label="About"/>
                        </Grid>

                    </Grid>
                </Box>
            </Card>
        </>
    );
};

export default OrganizationInformation;
