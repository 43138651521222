import {combineReducers} from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import allowancesReducer from './slices/allowances';
import workspacesReducer from './slices/workspaces';
import organizationReducer from './slices/organization';
import roleReducer from './slices/role';
import catalogReducer from './slices/catalog';
import packageReducer from './slices/package';
import integrationsReducer from './slices/integrations';
import permissionsReducer from './slices/permissions';
import contentReducer from './slices/content';

// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

// const productPersistConfig = {
//     key: 'product',
//     storage,
//     keyPrefix: 'redux-',
//     whitelist: ['sortBy', 'checkout'],
// };

const rootReducer = combineReducers({
    allowances: allowancesReducer,
    content: contentReducer,
    catalog: catalogReducer,
    permissions: permissionsReducer,
    integrations: integrationsReducer,
    roles: roleReducer,
    packages: packageReducer,
    workspaces: workspacesReducer,
    organization: organizationReducer
});

export {rootPersistConfig, rootReducer};
