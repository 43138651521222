import PropTypes from 'prop-types';
// form
import {Controller, useFormContext} from 'react-hook-form';
// @mui
import AppTextField from "../input-fields/AppTextField";

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
    children: PropTypes.node,
    name: PropTypes.string,
};

export default function RHFSelect({name, children, ...other}) {
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => (
                <AppTextField
                    {...field}
                    select
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...other}
                >
                    {children}
                </AppTextField>
            )}
        />
    );
}
