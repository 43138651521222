import duotone from "icons/duotone";
import {PATH_MANAGEMENT} from "../../router/paths";

const navigationList = [
    {
        type: "label",
        label: "Dashboard",
        items: [
            {
                name: "LMS",
                path: "/dashboards",
                icon: duotone.PersonChalkboard,
            },
            {
                name: "SaaS",
                path: "/dashboards/saas",
                icon: duotone.LayerGroup,
            }
        ]
    },
    {
        type: "label",
        label: "Assets",
        items: [
            {
                name: "Offerings",
                iconify: 'ic:outline-local-offer',
                path: PATH_MANAGEMENT.offerings.list,
            },
            {
                name: "Content",
                iconify: 'carbon:content-view',
                path: PATH_MANAGEMENT.content.list,
            },
            {
                name: "Configurations",
                iconify: 'ic:baseline-data-object',
                path: PATH_MANAGEMENT.configurations.list,
            },
            {
                name: "Catalog",
                iconify: 'fluent-mdl2:product-catalog',
                path: PATH_MANAGEMENT.assets.list,
            },
            {
                name: "Packages",
                iconify: "mdi:package-variant",
                path: PATH_MANAGEMENT.packages.list,
            },
            {
                name: "Roles",
                iconify: "ph:lock-key",
                path: PATH_MANAGEMENT.roles.list,
            }
        ]
    },
    {
        type: "label",
        label: "Management",
        items: [
            {
                name: "Integrations",
                iconify: "mdi:transit-connection-variant",
                path: '/management/integrations'
            },
            {
                name: "Users",
                icon: duotone.UserProfile,
                path: '/management/users/list'
            }
        ]
    }
];

export const navigations = navigationList
