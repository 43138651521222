import LoadingScreen from "components/LoadingScreen";
import useSettings from "hooks/useSettings";
import DashboardLayoutV1 from "layouts/layout-v1/DashboardLayout";
import LayoutV2 from "layouts/layout-v2/LayoutV2";
import DashboardLayoutV3 from "layouts/layout-v3/DashboardLayout";
import {lazy, Suspense} from "react";
import {Navigate} from "react-router-dom";
import AuthGaurd from "../gaurds/AuthGaurd";

const Loadable = (Component) => (props) => {
    return (
        <Suspense fallback={<LoadingScreen/>}>
            <Component {...props} />
        </Suspense>
    );
};

// dashboards

const CRM = Loadable(lazy(() => import("../pages/dashboards/crm")));
const Sales = Loadable(lazy(() => import("../pages/dashboards/sales")));
const SalesV2 = Loadable(lazy(() => import("../pages/dashboards/sales-v2")));
const SaaS = Loadable(lazy(() => import("../pages/dashboards/saas")));
const ProjectManagement = Loadable(
    lazy(() => import("../pages/dashboards/project-management"))
);
const ProjectManagementV2 = Loadable(
    lazy(() => import("../pages/dashboards/project-management-v2"))
);
const JobManagement = Loadable(
    lazy(() => import("../pages/dashboards/job-management"))
);
const LearningManagement = Loadable(
    lazy(() => import("../pages/dashboards/learning-management"))
);

// assets

const Assets = Loadable(lazy(() => import("../pages/assets/catalog/assetList")));

const AssetsGrid = Loadable(lazy(() => import("../pages/assets/catalog/assetGrid")));

const AddEditAsset = Loadable(lazy(() => import("../page-sections/assets/catalog/add-edit-product")));

const RolesList = Loadable(lazy(() => import("../pages/assets/roles/roleList")));

const RolesEditAsset = Loadable(lazy(() => import("../pages/assets/roles/roleEdit")));

const RolesAddAsset = Loadable(lazy(() => import("../pages/assets/roles/roleAdd")));

const ConfigurationsList = Loadable(lazy(() => import("../pages/assets/configurations/configurationsList")));

const ConfigurationsEditAsset = Loadable(lazy(() => import("../pages/assets/configurations/configurationsEdit")));

const ConfigurationsAddAsset = Loadable(lazy(() => import("../pages/assets/configurations/configurationsAdd")));

const DataList = Loadable(lazy(() => import("../pages/assets/data/dataList")));

const DataEditAsset = Loadable(lazy(() => import("../pages/assets/data/dataEdit")));

const DataAddAsset = Loadable(lazy(() => import("../pages/assets/data/dataAdd")));

const PackagesList = Loadable(lazy(() => import("../pages/assets/packages/packageList")));

const PackagesEditAsset = Loadable(lazy(() => import("../pages/assets/packages/packageEdit")));

const PackagesAddAsset = Loadable(lazy(() => import("../pages/assets/packages/packageAdd")));

const OfferingsList = Loadable(lazy(() => import("../pages/assets/offerings/offeringList")));

const OfferingsEditAsset = Loadable(lazy(() => import("../pages/assets/offerings/offeringEdit")));

const OfferingsAddAsset = Loadable(lazy(() => import("../pages/assets/offerings/offeringAdd")));

const ContentList = Loadable(lazy(() => import("../pages/assets/content/contentList")));

const ContentEditAsset = Loadable(lazy(() => import("../pages/assets/content/contentEdit")));

const ContentAddAsset = Loadable(lazy(() => import("../pages/assets/content/contentAdd")));

// integrations

const Integrations = Loadable(lazy(() => import("../pages/integrations/integrationList")));

const IntegrationsJoltCRM = Loadable(lazy(() => import("../pages/integrations/joltcrm")));

const IntegrationDetails = Loadable(lazy(() => import("../pages/integrations/integrationDetails")));

// account

const Account = Loadable(lazy(() => import("../pages/accounts/account")));
const AccountV2 = Loadable(lazy(() => import("../pages/accounts/account-v2"))); // user and contact

const AddUser = Loadable(lazy(() => import("../pages/user-list/userAdd")));
const EditUser = Loadable(lazy(() => import("../pages/user-list/userEdit")));
const UserList = Loadable(
    lazy(() => import("../pages/user-list/user-list-view"))
);
const UserListV2 = Loadable(
    lazy(() => import("../pages/user-list/user-list-view-v2"))
);
const UserGrid = Loadable(
    lazy(() => import("../pages/user-list/user-grid-view"))
);
const UserGridV2 = Loadable(
    lazy(() => import("../pages/user-list/user-grid-view-v2"))
);
const ContactList = Loadable(
    lazy(() => import("../pages/contact-list/contact-list-view"))
);
const ContactGrid = Loadable(
    lazy(() => import("../pages/contact-list/contact-grid-view"))
);

// profile

const Profile = Loadable(lazy(() => import("../pages/profiles/profile")));
const ProfileV2 = Loadable(lazy(() => import("../pages/profiles/profile-v2"))); // profile

const DataTableV2 = Loadable(
    lazy(() => import("../pages/data-table/data-table-v2"))
);

// invoice

const InvoiceList = Loadable(lazy(() => import("../pages/invoice/list")));
const InvoiceList2 = Loadable(
    lazy(() => import("../pages/invoice/invoice-list-v2"))
);
const CreateInvoice = Loadable(lazy(() => import("../pages/invoice/create")));
const CreateInvoice2 = Loadable(
    lazy(() => import("../pages/invoice/add-invoice-v2"))
);
const InvoiceDetails = Loadable(lazy(() => import("../pages/invoice/details")));
const InvoiceDetails2 = Loadable(
    lazy(() => import("../pages/invoice/invoice-details-v2"))
);

// eCommerce

const Shop = Loadable(lazy(() => import("../pages/ecommerce/shop")));
const ShopV2 = Loadable(lazy(() => import("../pages/ecommerce/shop-v2")));
const Cart = Loadable(lazy(() => import("../pages/ecommerce/Cart")));
const Payment = Loadable(lazy(() => import("../pages/ecommerce/payment")));
const Checkout = Loadable(lazy(() => import("../pages/ecommerce/checkout")));
const CheckoutV2 = Loadable(
    lazy(() => import("../pages/ecommerce/checkout-v2"))
);
const ProductDetails = Loadable(
    lazy(() => import("../pages/ecommerce/product-details"))
);
const BillingAddress = Loadable(
    lazy(() => import("../pages/ecommerce/billing-address"))
);
const PaymentComplete = Loadable(
    lazy(() => import("../pages/ecommerce/payment-complete"))
);
const PaymentCompleteV2 = Loadable(
    lazy(() => import("../pages/ecommerce/payment-complete-v2"))
);

// pricing

const Pricing = Loadable(lazy(() => import("../pages/pricing"))); // pricing

const TodoList = Loadable(lazy(() => import("../pages/todo-list"))); // pricing

const Calendar = Loadable(lazy(() => import("../pages/calendar"))); // authentication

const Login = Loadable(lazy(() => import("../pages/authentication/login")));
const LoginV2 = Loadable(lazy(() => import("../pages/authentication/login-v2")));
const Register = Loadable(
    lazy(() => import("../pages/authentication/register"))
);
const RegisterV2 = Loadable(
    lazy(() => import("../pages/authentication/register-v2"))
);
const NewPassword = Loadable(
    lazy(() => import("../pages/authentication/new-password"))
);
const ForgetPassword = Loadable(
    lazy(() => import("../pages/authentication/forget-password"))
);
const ForgetPasswordV2 = Loadable(
    lazy(() => import("../pages/authentication/forget-password-v2"))
);
const TwoStepVerify = Loadable(
    lazy(() => import("../pages/authentication/two-step-verification"))
);

// admin ecommerce

const ProductList = Loadable(
    lazy(() => import("../pages/admin-ecommerce/product-list"))
);
const ProductGrid = Loadable(
    lazy(() => import("../pages/admin-ecommerce/product-grid"))
);
const CreateProduct = Loadable(
    lazy(() => import("../pages/admin-ecommerce/create-product"))
);
const CustomerManagement = Loadable(
    lazy(() => import("../pages/admin-ecommerce/customer-management"))
);
const OrderManagement = Loadable(
    lazy(() => import("../pages/admin-ecommerce/order-management"))
);
const ProductManagement = Loadable(
    lazy(() => import("../pages/admin-ecommerce/product-management"))
);

// REGISTRATION

const RegisterOrganization = Loadable(lazy(() => import("../pages/registration/register-organization")));

// CHAT

const ChatV1 = Loadable(lazy(() => import("../pages/chats/chat-v1")));
const ChatV2 = Loadable(lazy(() => import("../pages/chats/chat-v2"))); // user projects

const ProjectDetails = Loadable(
    lazy(() => import("../pages/projects/project-details"))
);
const ProjectV1 = Loadable(lazy(() => import("../pages/projects/project-v1")));
const ProjectV2 = Loadable(lazy(() => import("../pages/projects/project-v2")));
const TeamMember = Loadable(lazy(() => import("../pages/projects/team-member")));
const ProjectV3 = Loadable(lazy(() => import("../pages/projects/project-v3"))); // Landing page

const HomePage = Loadable(lazy(() => import("../pages/home")));
const AboutPage = Loadable(lazy(() => import("../pages/about")));
const ContactPage = Loadable(lazy(() => import("../pages/contact")));
const PricingPage = Loadable(lazy(() => import("../pages/pricing-v2")));
const PrivacyPage = Loadable(lazy(() => import("../pages/privacy"))); // 404/Error page

const Error = Loadable(lazy(() => import("../pages/404")));

const ActiveLayout = () => {
    const {settings} = useSettings(); // console.log(settings);

    switch (settings.activeLayout) {
        case "layout1":
            return <DashboardLayoutV1/>;

        case "layout2":
            return <LayoutV2/>;

        case "layout3":
            return <DashboardLayoutV3/>;

        default:
            return <DashboardLayoutV1/>;
    }
};

const routes = () => {
    return [
        ...authRoutes,
        {
            path: "dashboards",
            element: <AuthGaurd><ActiveLayout/></AuthGaurd>,
            children: dashboardRoutes,
        },
        {
            path: "management",
            element: <ActiveLayout/>,
            children: managementRouts,
        },
        {
            path: "registration",
            children: registrationRoutes,
        },
        {
            path: "integrations",
            children: integrationRoutes,
        },
        {
            path: "*",
            element: <Error/>,
        },
    ];
};

const registrationRoutes = [
    {
        path: "organization",
        element: <AuthGaurd>
            <RegisterOrganization/>
        </AuthGaurd>,
    }
]

const integrationRoutes = [
    {
        path: "joltcrm",
        element: <AuthGaurd>
            <IntegrationsJoltCRM/>
        </AuthGaurd>,
    }
]

const managementRouts = [
    {
        path: "assets/list",
        element: <AuthGaurd>
            <Assets/>
        </AuthGaurd>,
    },
    {
        path: "assets/grid",
        element: <AuthGaurd>
            <AssetsGrid/>
        </AuthGaurd>,
    },
    {
        path: "assets/new",
        element: <AuthGaurd>
            <AddEditAsset/>
        </AuthGaurd>,
    },
    {
        path: "assets/:id/edit",
        element: <AuthGaurd>
            <AddEditAsset/>
        </AuthGaurd>,
    },
    {
        path: "data/list",
        element: <AuthGaurd>
            <DataList/>
        </AuthGaurd>,
    },
    {
        path: "data/new",
        element: <AuthGaurd>
            <DataAddAsset/>
        </AuthGaurd>,
    },
    {
        path: "data/edit",
        element: <AuthGaurd>
            <DataEditAsset/>
        </AuthGaurd>,
    },
    {
        path: "data/details",
        element: <AuthGaurd>
            <DataEditAsset/>
        </AuthGaurd>,
    },
    {
        path: "configurations/list",
        element: <AuthGaurd>
            <ConfigurationsList/>
        </AuthGaurd>,
    },
    {
        path: "configurations/new",
        element: <AuthGaurd>
            <ConfigurationsAddAsset/>
        </AuthGaurd>,
    },
    {
        path: "configurations/edit",
        element: <AuthGaurd>
            <ConfigurationsEditAsset/>
        </AuthGaurd>,
    },
    {
        path: "configurations/details",
        element: <AuthGaurd>
            <ConfigurationsEditAsset/>
        </AuthGaurd>,
    },
    {
        path: "roles/list",
        element: <AuthGaurd>
            <RolesList/>
        </AuthGaurd>,
    },
    {
        path: "roles/new",
        element: <AuthGaurd>
            <RolesAddAsset/>
        </AuthGaurd>,
    },
    {
        path: "roles/:identifier/edit",
        element: <AuthGaurd>
            <RolesEditAsset/>
        </AuthGaurd>,
    },
    {
        path: "offerings/list",
        element: <AuthGaurd>
            <OfferingsList/>
        </AuthGaurd>,
    },
    {
        path: "offerings/new",
        element: <AuthGaurd>
            <OfferingsAddAsset/>
        </AuthGaurd>,
    },
    {
        path: "offerings/:identifier/edit",
        element: <AuthGaurd>
            <OfferingsEditAsset/>
        </AuthGaurd>,
    },
    {
        path: "offerings/copy",
        element: <AuthGaurd>
            <OfferingsAddAsset/>
        </AuthGaurd>,
    },
    {
        path: "content/list",
        element: <AuthGaurd>
            <ContentList/>
        </AuthGaurd>,
    },
    {
        path: "content/new",
        element: <AuthGaurd>
            <ContentAddAsset/>
        </AuthGaurd>,
    },
    {
        path: "content/:identifier/edit",
        element: <AuthGaurd>
            <ContentEditAsset/>
        </AuthGaurd>,
    },
    {
        path: "content/copy",
        element: <AuthGaurd>
            <ContentAddAsset/>
        </AuthGaurd>,
    },
    {
        path: "packages/list",
        element: <AuthGaurd>
            <PackagesList/>
        </AuthGaurd>,
    },
    {
        path: "packages/new",
        element: <AuthGaurd>
            <PackagesAddAsset/>
        </AuthGaurd>,
    },
    {
        path: "packages/:identifier/edit",
        element: <AuthGaurd>
            <PackagesEditAsset/>
        </AuthGaurd>,
    },
    {
        path: "integrations",
        element: <AuthGaurd>
            <Integrations/>
        </AuthGaurd>,
    },
    {
        path: "integrations/details/:id",
        element: <AuthGaurd>
            <IntegrationDetails/>
        </AuthGaurd>,
    },
    {
        path: "users/new",
        element: <AuthGaurd>
            <AddUser/>
        </AuthGaurd>,
    },
    {
        path: "users/list",
        element: <AuthGaurd>
            <UserListV2/>
        </AuthGaurd>,
    },
    {
        path: "users/edit",
        element: <AuthGaurd>
            <EditUser/>
        </AuthGaurd>,
    },
]

const authRoutes = [
    {
        path: "/",
        element: <AuthGaurd>
            <Navigate to="/home"/>
        </AuthGaurd>,
    },
    {
        path: "home",
        element: <AuthGaurd>
            <HomePage/>
        </AuthGaurd>,
    },
    {
        path: "login-v2",
        element: <Login/>,
    },
    {
        path: "login",
        element: <LoginV2/>,
    },
    {
        path: "register",
        element: <Register/>,
    },
    {
        path: "register-v2",
        element: <RegisterV2/>,
    },
    {
        path: "new-password",
        element: <NewPassword/>,
    },
    {
        path: "forget-password",
        element: <ForgetPassword/>,
    },
    {
        path: "forget-password-v2",
        element: <ForgetPasswordV2/>,
    },
    {
        path: "two-step-verification",
        element: <TwoStepVerify/>,
    },
];

const dashboardRoutes = [
    {
        path: "",
        element: <AuthGaurd>
            <LearningManagement/>
        </AuthGaurd>,
    },
    {
        path: "job-management",
        element: <AuthGaurd>
            <JobManagement/>
        </AuthGaurd>,
    },
    {
        path: "sales",
        element: <AuthGaurd>
            <Sales/>
        </AuthGaurd>,
    },
    {
        path: "sales-v2",
        element: <AuthGaurd>
            <SalesV2/>
        </AuthGaurd>,
    },
    {
        path: "crm",
        element: <AuthGaurd>
            <CRM/>
        </AuthGaurd>,
    },
    {
        path: "saas",
        element: <AuthGaurd>
            <SaaS/>
        </AuthGaurd>,
    },
    {
        path: "sub-child-v1",
        element: <AuthGaurd>
            <SalesV2/>
        </AuthGaurd>,
    },
    {
        path: "sub-child-v2",
        element: <AuthGaurd>
            <ProjectManagement/>
        </AuthGaurd>,
    },
    {
        path: "sub-child-v3",
        element: <AuthGaurd>
            <ProjectManagementV2/>
        </AuthGaurd>,
    },
    {
        path: "project-management",
        element: <AuthGaurd>
            <ProjectManagement/>
        </AuthGaurd>,
    },
    {
        path: "project-management-v2",
        element: <AuthGaurd>
            <ProjectManagementV2/>
        </AuthGaurd>,
    },
    {
        path: "account",
        element: <AuthGaurd>
            <Account/>
        </AuthGaurd>,
    },
    {
        path: "account-v2",
        element: <AuthGaurd>
            <AccountV2/>
        </AuthGaurd>,
    },
    {
        path: "profile",
        element: <AuthGaurd>
            <Profile/>
        </AuthGaurd>,
    },
    {
        path: "profile-v2",
        element: <AuthGaurd>
            <ProfileV2/>
        </AuthGaurd>,
    },
    {
        path: "data-table-v2",
        element: <AuthGaurd>
            <DataTableV2/>
        </AuthGaurd>,
    },
    {
        path: "add-user",
        element: <AuthGaurd>
            <AddUser/>
        </AuthGaurd>,
    },
    {
        path: "user-list",
        element: <AuthGaurd>
            <UserList/>
        </AuthGaurd>,
    },
    {
        path: "user-list-v2",
        element: <AuthGaurd>
            <UserListV2/>
        </AuthGaurd>,
    },
    {
        path: "user-grid",
        element: <AuthGaurd>
            <UserGrid/>
        </AuthGaurd>,
    },
    {
        path: "user-grid-v2",
        element: <AuthGaurd>
            <UserGridV2/>
        </AuthGaurd>,
    },
    {
        path: "contact-list",
        element: <AuthGaurd>
            <ContactList/>
        </AuthGaurd>,
    },
    {
        path: "contact-grid",
        element: <AuthGaurd>
            <ContactGrid/>
        </AuthGaurd>,
    },
    {
        path: "invoice-list",
        element: <AuthGaurd>
            <InvoiceList/>
        </AuthGaurd>,
    },
    {
        path: "invoice-list-v2",
        element: <AuthGaurd>
            <InvoiceList2/>
        </AuthGaurd>,
    },
    {
        path: "create-invoice",
        element: <AuthGaurd>
            <CreateInvoice/>
        </AuthGaurd>,
    },
    {
        path: "create-invoice-v2",
        element: <AuthGaurd>
            <CreateInvoice2/>
        </AuthGaurd>,
    },
    {
        path: "invoice-details",
        element: <AuthGaurd>
            <InvoiceDetails/>
        </AuthGaurd>,
    },
    {
        path: "invoice-details-v2",
        element: <AuthGaurd>
            <InvoiceDetails2/>
        </AuthGaurd>,
    },
    {
        path: "project-v1",
        element: <AuthGaurd>
            <ProjectV1/>
        </AuthGaurd>,
    },
    {
        path: "project-v2",
        element: <AuthGaurd>
            <ProjectV2/>
        </AuthGaurd>,
    },
    {
        path: "project-v3",
        element: <AuthGaurd>
            <ProjectV3/>
        </AuthGaurd>,
    },
    {
        path: "team-member",
        element: <AuthGaurd>
            <TeamMember/>
        </AuthGaurd>,
    },
    {
        path: "project-details",
        element: <AuthGaurd>
            <ProjectDetails/>
        </AuthGaurd>,
    },
    {
        path: "shop",
        element: <AuthGaurd>
            <Shop/>
        </AuthGaurd>,
    },
    {
        path: "shop-v2",
        element: <AuthGaurd>
            <ShopV2/>
        </AuthGaurd>,
    },
    {
        path: "cart",
        element: <AuthGaurd>
            <Cart/>
        </AuthGaurd>,
    },
    {
        path: "payment",
        element: <AuthGaurd>
            <Payment/>
        </AuthGaurd>,
    },
    {
        path: "checkout",
        element: <AuthGaurd>
            <Checkout/>
        </AuthGaurd>,
    },
    {
        path: "checkout-v2",
        element: <AuthGaurd>
            <CheckoutV2/>
        </AuthGaurd>,
    },
    {
        path: "product-details",
        element: <AuthGaurd>
            <ProductDetails/>
        </AuthGaurd>,
    },
    {
        path: "billing-address",
        element: <AuthGaurd>
            <BillingAddress/>
        </AuthGaurd>,
    },
    {
        path: "payment-complete",
        element: <AuthGaurd>
            <PaymentComplete/>
        </AuthGaurd>,
    },
    {
        path: "payment-complete-v2",
        element: <AuthGaurd>
            <PaymentCompleteV2/>
        </AuthGaurd>,
    },
    {
        path: "product-list",
        element: <AuthGaurd>
            <ProductList/>
        </AuthGaurd>,
    },
    {
        path: "product-grid",
        element: <AuthGaurd>
            <ProductGrid/>
        </AuthGaurd>,
    },
    {
        path: "create-product",
        element: <AuthGaurd>
            <CreateProduct/>
        </AuthGaurd>,
    },
    {
        path: "customer-management",
        element: <AuthGaurd>
            <CustomerManagement/>
        </AuthGaurd>,
    },
    {
        path: "order-management",
        element: <AuthGaurd>
            <OrderManagement/>
        </AuthGaurd>,
    },
    {
        path: "product-management",
        element: <AuthGaurd>
            <ProductManagement/>
        </AuthGaurd>,
    },
    {
        path: "chat-v1",
        element: <AuthGaurd>
            <ChatV1/>
        </AuthGaurd>,
    },
    {
        path: "chat-v2",
        element: <AuthGaurd>
            <ChatV2/>
        </AuthGaurd>,
    },
    {
        path: "pricing",
        element: <AuthGaurd>
            <Pricing/>
        </AuthGaurd>,
    },
    {
        path: "todo-list",
        element: <AuthGaurd>
            <TodoList/>
        </AuthGaurd>,
    },
    {
        path: "calender",
        element: <AuthGaurd>
            <Calendar/>
        </AuthGaurd>,
    },
    {
        path: "about",
        element: <AuthGaurd>
            <AboutPage/>
        </AuthGaurd>,
    },
    {
        path: "contact",
        element: <ContactPage/>,
    },
    {
        path: "pricing",
        element: <PricingPage/>,
    },
    {
        path: "privacy",
        element: <PrivacyPage/>,
    },
];
export default routes;
