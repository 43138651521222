// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_CUSTOMER = '';

const ROOTS_MANAGEMENT = '/management';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
    root: ROOTS_CUSTOMER,
    register: {
        root: path(ROOTS_CUSTOMER, '/registration/organization')
    },
    main: {
        root: path(ROOTS_CUSTOMER, '/')
    },
    integration: {
        root: path(ROOTS_CUSTOMER, '/management/integrations'),
        list: path(ROOTS_CUSTOMER, '/management/integrations/list'),
        new: path(ROOTS_CUSTOMER, '/management/integrations/new'),
        view: (id) => path(ROOTS_CUSTOMER, `/management/integrations/details/${id}`),
        edit: (id) => path(ROOTS_CUSTOMER, `/management/integrations/${id}/edit`),
    },
};

export const PATH_MANAGEMENT = {
    root: ROOTS_MANAGEMENT,
    assets: {
        root: path(ROOTS_MANAGEMENT, '/assets'),
        list: path(ROOTS_MANAGEMENT, '/assets/list'),
        grid: path(ROOTS_MANAGEMENT, '/assets/grid'),
        new: path(ROOTS_MANAGEMENT, '/assets/new'),
        view: (id) => path(ROOTS_MANAGEMENT, `/assets/details/${id}`),
        edit: (id) => path(ROOTS_MANAGEMENT, `/assets/${id}/edit`),
    },
    roles: {
        root: path(ROOTS_MANAGEMENT, '/roles'),
        list: path(ROOTS_MANAGEMENT, '/roles/list'),
        grid: path(ROOTS_MANAGEMENT, '/roles/grid'),
        new: path(ROOTS_MANAGEMENT, '/roles/new'),
        view: (id) => path(ROOTS_MANAGEMENT, `/roles/details/${id}`),
        edit: (id) => path(ROOTS_MANAGEMENT, `/roles/${id}/edit`),
    },
    data: {
        root: path(ROOTS_MANAGEMENT, '/data'),
        list: path(ROOTS_MANAGEMENT, '/data/list'),
        new: path(ROOTS_MANAGEMENT, '/data/new'),
        view: path(ROOTS_MANAGEMENT, `/data/details`),
        edit: path(ROOTS_MANAGEMENT, `/data/edit`),
    },
    configurations: {
        root: path(ROOTS_MANAGEMENT, '/configurations'),
        list: path(ROOTS_MANAGEMENT, '/configurations/list'),
        new: path(ROOTS_MANAGEMENT, '/configurations/new'),
        view: path(ROOTS_MANAGEMENT, `/configurations/details`),
        edit: path(ROOTS_MANAGEMENT, `/configurations/edit`),
    },
    offerings: {
        root: path(ROOTS_MANAGEMENT, '/offerings'),
        list: path(ROOTS_MANAGEMENT, '/offerings/list'),
        grid: path(ROOTS_MANAGEMENT, '/offerings/grid'),
        new: path(ROOTS_MANAGEMENT, '/offerings/new'),
        view: (id) => path(ROOTS_MANAGEMENT, `/offerings/details/${id}`),
        edit: (id) => path(ROOTS_MANAGEMENT, `/offerings/${id}/edit`),
        copy: path(ROOTS_MANAGEMENT, `/offerings/copy`),
    },
    content: {
        root: path(ROOTS_MANAGEMENT, '/content'),
        list: path(ROOTS_MANAGEMENT, '/content/list'),
        grid: path(ROOTS_MANAGEMENT, '/content/grid'),
        new: path(ROOTS_MANAGEMENT, '/content/new'),
        view: (id) => path(ROOTS_MANAGEMENT, `/content/details/${id}`),
        edit: (id) => path(ROOTS_MANAGEMENT, `/content/${id}/edit`),
        copy: path(ROOTS_MANAGEMENT, `/content/copy`),
    },
    packages: {
        root: path(ROOTS_MANAGEMENT, '/packages'),
        list: path(ROOTS_MANAGEMENT, '/packages/list'),
        grid: path(ROOTS_MANAGEMENT, '/packages/grid'),
        new: path(ROOTS_MANAGEMENT, '/packages/new'),
        view: (id) => path(ROOTS_MANAGEMENT, `/packages/details/${id}`),
        edit: (id) => path(ROOTS_MANAGEMENT, `/packages/${id}/edit`),
    },
    users: {
        root: path(ROOTS_MANAGEMENT, '/users'),
        list: path(ROOTS_MANAGEMENT, '/users/list'),
        grid: path(ROOTS_MANAGEMENT, '/users/grid'),
        new: path(ROOTS_MANAGEMENT, '/users/new'),
        view: (id) => path(ROOTS_MANAGEMENT, `/users/details/${id}`),
        edit: path(ROOTS_MANAGEMENT, `/users/edit`),
    },
};
