import {useCallback, useEffect, useState} from "react";
import axios from "../utils/axios";

export default function useGoogleAddressParser(onAddressChange) {
    const [address, setAddress] = useState(null);

    const [addressComponents, setAddressComponents] = useState(null)

    const [locationData, setLocationData] = useState(null)

    const isStreetNumber = (component) => component.types.includes('street_number');

    const isStreetName = (component) => component.types.includes('route') || component.types.includes('plus_code');

    const isCity = (component) => component.types.includes('locality');

    const isState = (component) => component.types.includes('administrative_area_level_1');

    const isCountry = (component) => component.types.includes('country');

    const isPostalCode = (component) => component.types.includes('postal_code');

    const parseLocalAddress = (location) => {
        if (!Array.isArray(location.address_components)) {
            throw Error('Address Components is not an array');
        }

        if (!location.address_components.length) {
            throw Error('Address Components is empty');
        }

        const newAddress = {}

        newAddress.photoURL = location.photos?.length > 0 ? `https://maps.googleapis.com/maps/api/place/photo?maxheight=200&key=${process.env.REACT_APP_GOOGLE_API_KEY}&photoreference=${location.photos[0].photo_reference}` : ''

        for (let i = 0; i < location.address_components.length; i += 1) {
            const component = location.address_components[i];

            if (isStreetNumber(component)) {
                newAddress.streetNumber = component.long_name;
            }

            if (isStreetName(component)) {
                newAddress.streetName = component.long_name;
            }

            if (isCity(component)) {
                newAddress.city = component.long_name;
            }

            if (isCountry(component)) {
                newAddress.country = component.short_name || component.long_name;
            }

            if (isState(component)) {
                newAddress.stateProvidence = component.short_name || component.long_name;
            }

            if (isPostalCode(component)) {
                newAddress.postalCode = component.long_name;
            }
        }

        newAddress.address = `${newAddress.streetNumber || ''} ${newAddress.streetName || ''}`.trim();

        newAddress.location = location.geometry.location;
        newAddress.googleLocationId = location.place_id;

        return newAddress;
    }

    const parseAddress = useCallback(() => {
        if (!Array.isArray(addressComponents)) {
            throw Error('Address Components is not an array');
        }

        if (!addressComponents.length) {
            throw Error('Address Components is empty');
        }

        const newAddress = {}

        newAddress.photoURL = locationData.photos?.length > 0 ? `https://maps.googleapis.com/maps/api/place/photo?maxheight=200&key=${process.env.REACT_APP_GOOGLE_API_KEY}&photoreference=${locationData.photos[0].photo_reference}` : ''

        for (let i = 0; i < addressComponents.length; i += 1) {
            const component = addressComponents[i];

            if (isStreetNumber(component)) {
                newAddress.streetNumber = component.long_name;
            }

            if (isStreetName(component)) {
                newAddress.streetName = component.long_name;
            }

            if (isCity(component)) {
                newAddress.city = component.long_name;
            }

            if (isCountry(component)) {
                newAddress.country = component.short_name || component.long_name;
            }

            if (isState(component)) {
                newAddress.stateProvidence = component.short_name || component.long_name;
            }

            if (isPostalCode(component)) {
                newAddress.postalCode = component.long_name;
            }
        }

        newAddress.address = `${newAddress.streetNumber || ''} ${newAddress.streetName || ''}`.trim();

        newAddress.location = locationData.geometry.location;
        newAddress.googleLocationId = locationData.place_id;

        setAddress(newAddress)
    }, [addressComponents, locationData])

    useEffect(() => {
        if (address) {
            onAddressChange && onAddressChange(address)
        }
    }, [address, onAddressChange]);

    useEffect(() => {
        if (parseAddress && addressComponents && locationData) {
            parseAddress();
        }
    }, [parseAddress, addressComponents, locationData]);

    const getAddressDetails = (data) => new Promise((resolve, reject) => {
        axios.get(`/api/location/placeid?placeid=${data.place_id}`)
            .then((result) => {
                setComponents(result.data.location);
                resolve();
            })
            .catch((error) => {
                reject(error)
            })
    })

    const isValid = () => {
        let valid = true;
        const addressKeys = Object.keys(address);
        addressKeys.forEach(k => {
            if (!address[k]) {
                valid = false;
            }
        });
        return valid;
    }

    const setComponents = (locationData) => {
        setAddressComponents(locationData.address_components);
        setLocationData(locationData)
    }

    return {
        isValid,
        getAddressDetails,
        setComponents,
        parseAddress,
        parseLocalAddress,
        address
    };
}
