import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    permissions: [],
    permissionsLoaded: false
};

const slice = createSlice({
    name: 'package',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET PACKAGES
        getPermissionsSuccess(state, action) {
            state.isLoading = false;
            state.permissionsLoaded = true;
            state.permissions = action.payload;
        },

        // GET PACKAGES
        createPermissionsSuccess(state, action) {
            state.isLoading = false;
            state.permissionsLoaded = true;
            state.permissions = [...state.permissions, ...action.payload];
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPermissions(workspace, organization) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/permissions', {params: {workspace, organization}});
            dispatch(slice.actions.getPermissionsSuccess(response.data.permissions));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createAllowance(allowance) {
    return async () => {
        console.log({allowance})
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put('/api/permissions', {permissions: [allowance]});
            dispatch(slice.actions.createPermissionsSuccess(response.data.permissions));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

