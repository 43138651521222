import {useSelector} from "../redux/store";
import {useEffect, useState} from "react";

const useEnvironment = () => {

    const [environmentReady, setEnvironmentReady] = useState(false);

    const {
        activeWorkspace,
        isLoading: workspaceLoading,
        workspacesCollected,
        workspaces
    } = useSelector((state) => state.workspaces);

    const {
        activeOrganization,
        isLoading: organizationLoading,
        organizationCollected,
        organizationsCollected,
        organizations
    } = useSelector((state) => state.organization);

    const buildDataObject = (object) => {
        if (!activeWorkspace) {
            throw new Error("Use Environment Build Data Object: Active Workspace is not set and is required to build a data object.")
        }

        if (!activeOrganization) {
            throw new Error("Use Environment Build Data Object: Active Organization is not set and is required to build a data object")
        }

        return {...object, organization: activeOrganization._id, workspace: activeWorkspace._id}
    }

    useEffect(() => {
        // console.log({
        //     environmentReady: (organizationsCollected && organizationCollected && activeOrganization && workspacesCollected && !organizationLoading && !workspaceLoading),
        //     organizationsCollected,
        //     organizationCollected,
        //     activeOrganization,
        //     workspacesCollected,
        //     organizationLoading,
        //     workspaceLoading
        // })
        setEnvironmentReady((organizationsCollected && organizationCollected && activeOrganization && workspacesCollected && !organizationLoading && !workspaceLoading))
    }, [organizationsCollected, organizationCollected, activeOrganization, workspacesCollected, organizationLoading, workspaceLoading])

    const getCurrentOrgDetails = () => {
        return activeOrganization
    }

    return {
        buildDataObject,
        getCurrentOrgDetails,
        workspaces,
        organizations,
        workspace: activeWorkspace?._id,
        organization: activeOrganization?._id,
        environmentReady
    };
};

useEnvironment.propTypes = {};

export default useEnvironment;
