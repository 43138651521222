import {Select, styled} from "@mui/material";
import React from "react";

const StyledSelect = styled(Select)(({theme}) => ({
    "& .MuiOutlinedInput-input": {
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        borderColor: theme.palette.action.disabled,
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.action.hover,
    },
    "& .MuiInputLabel-root": {
        fontWeight: 500,
        color: theme.palette.text.disabled,
    },
    "& .MuiInputLabel-root.Mui-focused": {
        fontWeight: 600,
    },
    "& .MuiSvgIcon-root": {
        color: theme.palette.text.disabled,
    },
}));

const AppSelect = ({children, ...props}) => {
    return <StyledSelect {...props}>{children}</StyledSelect>;
};

export default AppSelect;
