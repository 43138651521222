import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
// @mui
import {Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
//
import RejectionFiles from './RejectionFiles';
import {AddAPhotoRounded} from "@mui/icons-material";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    width: 144,
    height: 144,
    margin: 'auto',
    borderRadius: '50%',
    border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled('div')({
    zIndex: 10,
    width: '100%',
    height: '100%',
    outline: 'none',
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '50%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {width: '100%', height: '100%'},
    '&:hover': {
        cursor: 'pointer',
        '& .placeholder': {
            zIndex: 9,
        },
    },
});

const PlaceholderStyle = styled('div')(({theme}) => ({
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {opacity: 0.72},
}));

// ----------------------------------------------------------------------

UploadAvatar.propTypes = {
    error: PropTypes.bool,
    file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    helperText: PropTypes.node,
    sx: PropTypes.object,
    avatarProps: PropTypes.object,
};

export default function UploadAvatar({error, file, helperText, sx, avatarProps, ...other}) {
    const {getRootProps, getInputProps, isDragActive, isDragReject, fileRejections} = useDropzone({
        multiple: false,
        ...other,
    });

    return (
        <>
            <RootStyle
                sx={{
                    ...((isDragReject || error) && {
                        borderColor: 'error.light',
                    }),
                    zIndex: 10,
                    ...sx,
                }}
            >
                <DropZoneStyle
                    {...getRootProps()}
                    sx={{
                        border: '2px solid #ffffff',
                        bgcolor: 'grey.100',
                        ...(isDragActive && {opacity: 0.72}),
                    }}
                    {...avatarProps}
                >
                    <input {...getInputProps()} />

                    {file && <img alt="avatar" src={file} style={{objectFit: 'cover'}}/>}

                    <PlaceholderStyle
                        className="placeholder"
                        sx={{
                            ...(file && {
                                opacity: 0,
                                color: 'common.white',
                                bgcolor: 'grey.600',
                                '&:hover': {opacity: 0.72},
                            }),
                            ...((isDragReject || error) && {
                                bgcolor: 'error.lighter',
                            }),
                        }}
                    >
                        <AddAPhotoRounded sx={{width: 24, height: 24, mb: 1}}/>
                        <Typography variant="caption">{file ? 'Update photo' : 'Upload photo'}</Typography>
                    </PlaceholderStyle>
                </DropZoneStyle>
            </RootStyle>

            {helperText && helperText}

            {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections}/>}
        </>
    );
}
