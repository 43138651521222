import {Backdrop, Dialog, useMediaQuery, useTheme} from "@mui/material";
import PropTypes from "prop-types";
import {forwardRef} from "react";


const DialogWindow = forwardRef(({open, ...props}, ref) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const {children, ...dialogOptions} = props;

    return (
        <Backdrop
            sx={{
                top: 0,
                left: 0,
                position: 'fixed',
                zIndex: theme.zIndex.snackbar + 3,
                backdropFilter: "blur(6px)",
                backgroundColor: "transparent",
            }}
            open={open}
        >
            <Dialog
                ref={ref}
                sx={{zIndex: theme.zIndex.snackbar + 4}}
                hideBackdrop
                open={open}
                maxWidth={"lg"}
                {...dialogOptions}
                fullScreen={fullScreen}
            >
                {children}
            </Dialog>
        </Backdrop>
    )
})

DialogWindow.propTypes = {
    open: PropTypes.bool.isRequired
}

export default DialogWindow
