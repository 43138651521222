import {useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useAuth0} from "@auth0/auth0-react";
import LoadingScreen from "../components/LoadingScreen";
import useAuth from "../hooks/useAuth";

const Authenticated = (props) => {
    const {children} = props;
    const {user} = useAuth()
    const {isAuthenticated, isLoading} = useAuth0();
    const location = useLocation();
    const [requestedLocation, setRequestedLocation] = useState(window.localStorage.getItem('requestedLocation') || null);
    if (isLoading || (!user && isAuthenticated)) {
        return <LoadingScreen/>
    }

    if (!isLoading && !isAuthenticated && !location.search) {
        if (location.pathname !== requestedLocation) {
            window.localStorage.setItem('requestedLocation', location.pathname)
            setRequestedLocation(location.pathname);
        }

        window.location.href = process.env.REACT_APP_WEBSITE_URL
        // return <Navigate to={'/login'}/>;
        // return <Navigate to={requestedLocation}/>;
    }

    if (requestedLocation && location.pathname !== requestedLocation) {
        setRequestedLocation(null);
        window.localStorage.removeItem('requestedLocation')
        return <Navigate to={requestedLocation}/>;
    }

    return <>{children}</>;
};

Authenticated.propTypes = {
    children: PropTypes.node
};

export default Authenticated;
