import PropTypes from 'prop-types';
// form
import {Controller, useFormContext} from 'react-hook-form';
// @mui
import AppTextField from "../input-fields/AppTextField";

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
    name: PropTypes.string,
};

export default function RHFTextField({name, ...other}) {
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={
                ({field: {ref, ...field}, fieldState: {error}}) => (
                    <AppTextField {...field} inputRef={ref} fullWidth error={!!error}
                                  helperText={error?.message} {...other} />)
            }
        />
    );
}
