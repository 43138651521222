import {createSlice} from '@reduxjs/toolkit';
// utils
//
import {dispatch} from '../store';
import {each, find, findIndex} from "lodash";
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    catalogsCollected: false,
    error: null,
    selectedCard: null,
    cards: [],
    roles: [],
    packages: [],
    permissions: [],
    levels: []
};

const slice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setCardDataSuccess(state, action) {
            console.log('setCardDataSuccess')
            const {identifier, ...data} = action.payload;
            const cardIndex = findIndex(state.cards, {identifier});
            if (cardIndex >= 0) {
                const originalCard = state.cards[cardIndex];
                const newCard = {
                    ...originalCard,
                    ...data
                }
                state.cards[cardIndex] = newCard
            }
        },

        // GET ROLES
        getCatalogSuccess(state, action) {
            const {systemAssets, roles, packages, permissions} = action.payload;
            state.isLoading = false;
            state.catalogsCollected = true;
            state.cards = systemAssets;
            state.roles = roles;
            state.packages = packages;
            state.permissions = permissions;
        },

        setNoHighlights(state, action) {
            each(state.cards, (card, index) => {
                state.cards[index].highlighted = false
            })
        },

        setSelectedCardSuccess(state, action) {
            const {identifier} = action.payload;
            const cardIndex = findIndex(state.cards, {identifier});

            if (cardIndex >= 0) {
                state.selectedCard = state.cards[cardIndex];
                const originalCard = state.cards[cardIndex];
                const newCard = {
                    ...originalCard,
                    highlighted: true
                }
                state.cards[cardIndex] = newCard;
            } else {
                state.selectedCard = null;
            }
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setCardData(data) {
    return async () => {
        dispatch(slice.actions.setCardDataSuccess(data))
    }
}

// ----------------------------------------------------------------------

export function setSelected(identifier, cards) {
    return async () => {
        function highlightParentData(identifier, cards) {
            dispatch(slice.actions.setCardDataSuccess({identifier, highlighted: true}))
            const card = find(cards, {identifier})
            for (let i = 0; i < card.parentId.length; i += 1) {
                const identifier = card.parentId[i];
                highlightParentData(identifier, cards)
            }
        }

        function highlightChildrenData(identifier, cards) {
            dispatch(slice.actions.setCardDataSuccess({identifier, highlighted: true}))
            const card = find(cards, {identifier})
            for (let i = 0; i < card.children?.length || 0; i += 1) {
                const identifier = card.children[i];
                highlightChildrenData(identifier, cards)
            }
        }

        // Remove all highlights
        dispatch(slice.actions.setNoHighlights())

        // Set Selected
        dispatch(slice.actions.setSelectedCardSuccess({identifier}))

        const card = find(cards, {identifier})
        // Highlight parents path
        for (let i = 0; i < card.parentId.length; i += 1) {
            const identifier = card.parentId[i];
            highlightParentData(identifier, cards)
        }

        // Highlight children path
        for (let i = 0; i < card.children?.length || 0; i += 1) {
            const identifier = card.children[i];
            highlightChildrenData(identifier, cards)
        }
    }
}

// ----------------------------------------------------------------------

export function getCatalog(systemAssets, roles, packages, permissions) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getCatalogSuccess({
                systemAssets,
                roles,
                packages,
                permissions
            }));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
