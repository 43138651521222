import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {Controller, useFormContext} from "react-hook-form";
import {Autocomplete, CircularProgress, Grid, InputAdornment, Typography} from "@mui/material";
import parse from 'autosuggest-highlight/parse';
import {throttle} from "lodash"
import useGoogleAddressParser from "../../hooks/useGoogleAddressParser";
import {LocationOn} from "@mui/icons-material";
import AppTextField from "../input-fields/AppTextField";

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = {current: null};

// ----------------------------------------------------------------------

RHFGoogleAddress.propTypes = {
    user: PropTypes.object,
    name: PropTypes.string,
    friendlyNameName: PropTypes.string,
    nameBase: PropTypes.string,
};

export default function RHFGoogleAddress({name, user, friendlyNameName, nameBase, ...other}) {

    const {control, setValue} = useFormContext();
    const [friendlyName, setFriendlyName] = useState(user?.addressFriendlyName || '');

    const buildAddress = useCallback(() => {
        if (user?.address && user?.city && user?.state && user?.country) {
            return `${user.address}, ${user.city}, ${user.state}, ${user.country}`
        }
        return ''
    }, [user])

    const [initialValue] = useState(buildAddress());

    useEffect(() => {
        setFriendlyName(user?.addressFriendlyName || '');
    }, [user])

    useEffect(() => {
        setValue(friendlyNameName || 'addressFriendlyName', friendlyName);
    }, [friendlyName, friendlyNameName, setValue]);


    const onAddressChange = useCallback((newValue) => {
        const {
            address,
            city,
            stateProvidence,
            postalCode,
            country,
            location,
            googleLocationId
        } = newValue;

        setValue(nameBase ? `${nameBase}.address` : 'address', address)
        setValue(nameBase ? `${nameBase}.city` : 'city', city)
        setValue(nameBase ? `${nameBase}.state` : 'state', stateProvidence)
        setValue(nameBase ? `${nameBase}.zipCode` : 'zipCode', postalCode)
        setValue(nameBase ? `${nameBase}.country` : 'country', country)
        setValue(nameBase ? `${nameBase}.location` : 'location', location)
        setValue(nameBase ? `${nameBase}.googleLocationId` : 'googleLocationId', googleLocationId)
    }, [setValue, nameBase])

    if (!user) {
        return null;
    }

    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => (
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item sm={12} md={8}>
                        <GoogleMaps
                            {...field}
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                            {...other}
                            initialValue={initialValue}
                            onChange={onAddressChange}
                        />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <AppTextField
                            fullWidth
                            onChange={(event) => setFriendlyName(event.target.value)}
                            value={friendlyName}
                            label="Friendly name"
                        />
                    </Grid>
                </Grid>
            )}
        />
    );
}

// ----------------------------------------------------------------------


const GoogleMaps = React.forwardRef((props, ref) => {
    const {label, onChange, initialValue, error, helperText} = props

    const [value, setValue] = React.useState(initialValue || null);
    const [validatingAddress, setValidatingAddress] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);
    const {getAddressDetails} = useGoogleAddressParser(onChange);


    useEffect(() => setValue(null), [])

    useEffect(() => setValue(initialValue || null), [initialValue])

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({input: inputValue}, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            disablePortal
            ref={ref}
            id="google-map-demo"
            fullWidth
            style={{width: '100%'}}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            isOptionEqualToValue={(option, value) => {
                if (typeof value === 'string' && typeof option === 'string') {
                    return option === value;
                }
                if (typeof value === 'string' && typeof option !== 'string' && option?.terms) {
                    return value.includes(option?.terms[0].value) && value.includes(option?.terms[1].value) && value.includes(option?.terms[2].value)
                }
                return option.description === value.description
            }}
            onChange={(event, newValue) => {
                if (newValue) {
                    setOptions(newValue ? [newValue, ...options] : options);
                } else {
                    setOptions([])
                }
                setValue(newValue);
                if (newValue) {
                    setValidatingAddress(true)
                    getAddressDetails(newValue)
                        .then(() => {
                            try {
                                setValidatingAddress(false)
                            } catch (e) {
                                console.error(e)
                                setValidatingAddress(false)
                            }
                        })
                        .catch(() => {
                            setValue(newValue);
                            setOptions([])
                            setValidatingAddress(false)
                        })
                } else {
                    setValidatingAddress(false);
                    onChange('')
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => {
                if (validatingAddress) {
                    return (<AppTextField error={error} helperText={helperText} {...params}
                                          label={label || 'Address'} fullWidth
                                          InputProps={{
                                              endAdornment: <InputAdornment
                                                  position="start"><CircularProgress/></InputAdornment>,
                                          }}
                    />)
                }
                return (
                    <AppTextField error={error} helperText={helperText} {...params} label={label || 'Address'}
                                  fullWidth/>)

            }}
            renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <LocationOn sx={{color: 'text.secondary', mr: 2}}/>
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                    {part.text}
                  </span>
                                ))}

                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
})

GoogleMaps.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    initialValue: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
};
