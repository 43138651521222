import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
    large: PropTypes.bool,
};

export default function Logo({disabledLink = false, sx, large, ...rest}) {

    const logo = (
        <Box sx={{width: 40, height: 40, ...sx}}>
            <img {...rest} src={large ? '/logo512.png' : '/logo192.png'} alt={'logo'}/>
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}
