import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
// @mui
import {Box, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
//
import RejectionFiles from './RejectionFiles';
import {AddAPhotoRounded} from "@mui/icons-material";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    width: '100%',
    height: '100%',
    margin: 'auto',
    border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled('div')(({theme}) => ({
    zIndex: 0,
    width: '100%',
    height: '100%',
    outline: 'none',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {width: '100%', height: '100%'},
    '&:hover': {
        cursor: 'pointer',
        '& .placeholder': {
            zIndex: 9,
        },
        backgroundColor: theme.palette.grey["900"]
    },
}));

const PlaceholderStyle = styled('div')(({theme}) => ({
    top: 0,
    left: 0,
    height: '100%',
    width: "100%",
    overflow: "hidden",
    position: "absolute",
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {opacity: 0.72},
}));

// ----------------------------------------------------------------------

UploadCoverPhoto.propTypes = {
    error: PropTypes.bool,
    file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    helperText: PropTypes.node,
    sx: PropTypes.object,
};

export default function UploadCoverPhoto({error, file, helperText, sx, ...other}) {
    const {getRootProps, getInputProps, isDragActive, isDragReject, fileRejections} = useDropzone({
        multiple: false,
        ...other,
    });

    return (
        <Box sx={{height: '100%'}}>
            <RootStyle
                sx={{
                    ...((isDragReject || error) && {
                        borderColor: 'error.light',
                    }),
                    ...sx,
                }}
            >
                <DropZoneStyle
                    {...getRootProps()}
                    sx={{
                        bgcolor: 'grey.100',
                        ...(isDragActive && {opacity: 0.72}),
                        height: '100%',
                    }}
                >
                    <input {...getInputProps()} />

                    {file &&
                    <img
                        alt="avatar"
                        src={file}
                        width={'100%'}
                        style={{objectFit: 'cover'}}
                    />}

                    <PlaceholderStyle
                        className="placeholder"
                        sx={{
                            ...(file && {
                                opacity: 0,
                                color: 'common.white',
                                bgcolor: 'grey.600',
                                '&:hover': {opacity: 0.72},
                            }),
                            ...((isDragReject || error) && {
                                bgcolor: 'error.lighter',
                            }),
                        }}
                    >
                        <AddAPhotoRounded sx={{width: 24, height: 24, mb: 1}}/>
                        <Typography variant="caption">{file ? 'Update photo' : 'Upload photo'}</Typography>
                    </PlaceholderStyle>
                </DropZoneStyle>
            </RootStyle>

            {helperText && helperText}

            {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections}/>}
        </Box>
    );
}
