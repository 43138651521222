import React, {useCallback, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import * as phoneCountries from 'countries-phone-masks'
import {find} from "lodash";
import {IMaskInput} from 'react-imask';
// form
import {Controller, useFormContext} from 'react-hook-form';
// @mui
import {Box, IconButton, MenuItem, Stack} from '@mui/material';
// config
import {countries} from "../../config";

import Iconify from "../../icons/Iconify";
import AppTextField from "../input-fields/AppTextField";
import AppSelect from "../input-fields/AppSelect";


// ----------------------------------------------------------------------
// eslint-disable-prefer-arrow-callback
const TextMaskCustom = React.forwardRef((props, ref) => {
    const {onChange, mask, ...other} = props;

    return (
        <IMaskInput
            {...other}
            mask={mask}
            definitions={{
                '#': /[0-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({target: {name: props.name, value}})}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    mask: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};


RHFPhoneField.propTypes = {
    user: PropTypes.object,
    name: PropTypes.string,
    friendlyNameName: PropTypes.string,
    countryCodeName: PropTypes.string,
    countryName: PropTypes.string
};

export default function RHFPhoneField({name, user, countryCodeName, countryName, friendlyNameName, ...other}) {
    const {control, setValue} = useFormContext();
    const [countryCode, setCountryCode] = useState(user?.phoneCountry || 'US');
    const [friendlyName, setFriendlyName] = useState(user?.phoneFriendlyName || '');

    const getCountryCode = useCallback(
        () => find(countries, {code: countryCode})?.phone || '1',
        [countryCode]
    );

    useEffect(() => {
        setCountryCode(user?.phoneCountry || 'US');
        setFriendlyName(user?.phoneFriendlyName || '');
    }, [user])

    useEffect(() => {
        setValue(countryCodeName || 'phoneCountryCode', getCountryCode());
        setValue(countryName || 'phoneCountry', countryCode);
    }, [countryCode, countryCodeName, countryName, getCountryCode, setValue]);

    useEffect(() => {
        setValue(friendlyNameName || 'phoneFriendlyName', friendlyName);
    }, [friendlyName, countryCodeName, countryName, getCountryCode, setValue, friendlyNameName])

    const getFlagUrl = (code) => `https://staticassetsdolooma.blob.core.windows.net/web/assets/icons/flags/${code.toLowerCase()}.svg`;

    const getMask = () => find(phoneCountries, {iso: countryCode})?.mask || ''

    if (!user) {
        return null;
    }

    return (
        <Controller
            name={name}
            control={control}
            mask={getMask()}
            render={({field: {ref, ...field}, fieldState: {error}}) => {

                return (
                    <Stack
                        direction={{xs: 'column', md: 'row'}}
                        spacing={2}
                        sx={{width: '100%'}}
                    >
                        <Stack
                            direction={'row'}
                            spacing={2}
                            sx={{width: '100%'}}
                        >
                            <Box sx={{width: 95}}>
                                <AppSelect
                                    sx={{width: '100%', height: 56}}
                                    value={countryCode}
                                    onChange={(event) => setCountryCode(event.target.value)}
                                    renderValue={(code) => (
                                        <img src={getFlagUrl(code)} alt={'flag'} width={32} style={{marginRight: 5}}/>)}
                                >
                                    {countries.map((country) => (
                                        <MenuItem
                                            key={country.code}
                                            value={country.code}
                                        >
                                            <img src={getFlagUrl(country.code)} alt={'flag'} width={25}
                                                 style={{marginRight: 5}}/> {country.label}
                                        </MenuItem>
                                    ))}
                                </AppSelect>
                            </Box>
                            <Box sx={{width: '100%'}}>
                                <AppTextField
                                    {...field}
                                    inputRef={ref}
                                    value={field.value}
                                    fullWidth
                                    error={!!error}
                                    helperText={error?.message} {...other}
                                    InputProps={{
                                        endAdornment: <IconButton
                                            onClick={() => setValue(name, '')}><Iconify
                                            icon={'mdi:clear-circle-outline'}/></IconButton>,
                                        inputComponent: TextMaskCustom,
                                        inputProps: {
                                            mask: getMask(),
                                        },
                                    }}
                                    onChange={(event) => {
                                        const matches = event.target.value ? event.target.value.match(/\d+/g) || [] : [];
                                        if (matches.join('')) {
                                            return setValue(name, matches.join(''));
                                        }

                                        return '';
                                    }}
                                />
                            </Box>
                        </Stack>
                        <Box>
                            <AppTextField
                                onChange={(event) => setFriendlyName(event.target.value)}
                                fullWidth
                                value={friendlyName}
                                label='Friendly name'
                            />
                        </Box>
                    </Stack>
                )
            }}
        />
    );
}
