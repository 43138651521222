import PropTypes from 'prop-types';
// form
import {Controller, useFormContext} from 'react-hook-form';
// @mui
import {FormHelperText} from '@mui/material';
// type
import {UploadAvatar, UploadCoverPhoto, UploadMultiFile, UploadSingleFile} from '../upload';

// ----------------------------------------------------------------------

RHFUploadAvatar.propTypes = {
    name: PropTypes.string,
    avatarProps: PropTypes.object
};

export function RHFUploadAvatar({name, avatarProps, ...other}) {
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => {
                const checkError = !!error && !field.value;

                return (
                    <div>
                        <UploadAvatar error={checkError} {...other} avatarProps={avatarProps} file={field.value}/>
                        {checkError && (
                            <FormHelperText error sx={{px: 2, textAlign: 'center'}}>
                                {error.message}
                            </FormHelperText>
                        )}
                    </div>
                );
            }}
        />
    );
}

// ----------------------------------------------------------------------

RHFUploadCoverPhoto.propTypes = {
    name: PropTypes.string,
};

export function RHFUploadCoverPhoto({name, ...other}) {
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => {
                const checkError = !!error && !field.value;

                return (
                    <div style={{height: '100%'}}>
                        <UploadCoverPhoto error={checkError} {...other} file={field.value}/>
                        {checkError && (
                            <FormHelperText error sx={{px: 2, textAlign: 'center'}}>
                                {error.message}
                            </FormHelperText>
                        )}
                    </div>
                );
            }}
        />
    );
}

// ----------------------------------------------------------------------

RHFUploadSingleFile.propTypes = {
    name: PropTypes.string,
};

export function RHFUploadSingleFile({name, ...other}) {
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => {
                const checkError = !!error && !field.value;

                return (
                    <UploadSingleFile
                        accept="image/*"
                        file={field.value}
                        error={checkError}
                        helperText={
                            checkError && (
                                <FormHelperText error sx={{px: 2}}>
                                    {error.message}
                                </FormHelperText>
                            )
                        }
                        {...other}
                    />
                );
            }}
        />
    );
}

// ----------------------------------------------------------------------

RHFUploadMultiFile.propTypes = {
    name: PropTypes.string,
};

export function RHFUploadMultiFile({name, ...other}) {
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => {
                const checkError = !!error && field.value?.length === 0;

                return (
                    <UploadMultiFile
                        accept={other.accept || "image/*"}
                        files={field.value}
                        error={checkError}
                        helperText={
                            checkError && (
                                <FormHelperText error sx={{px: 2}}>
                                    {error?.message}
                                </FormHelperText>
                            )
                        }
                        {...other}
                    />
                );
            }}
        />
    );
}
