import {alpha, Box, ButtonBase, styled} from "@mui/material";
import {Paragraph, Span} from "components/Typography";
import {useLocation, useNavigate} from "react-router-dom";
// import {navigations} from "../layout-parts/navigation";
import SidebarAccordion from "./SidebarAccordion";
import Iconify from "../../icons/Iconify";
import {HasAccess, PermissionsLoading} from "../../contexts/PermissionsContext";
import useNavigation from "../../hooks/useNavigation";

const NavItemButton = styled(ButtonBase)(({theme, active}) => ({
    height: 44,
    width: "100%",
    borderRadius: 8,
    marginBottom: 4,
    padding: "0 18px",
    justifyContent: "flex-start",
    transition: "all 0.15s ease",
    ...(active && {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.06),
    }),
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));

const ListLabel = styled(Paragraph)(({theme, compact}) => ({
    fontWeight: 700,
    fontSize: "12px",
    marginTop: "20px",
    marginLeft: "15px",
    marginBottom: "10px",
    textTransform: "uppercase",
    transition: "all 0.15s ease",
    color: theme.palette.text.secondary,
    ...(compact && {
        opacity: 0,
        width: 0,
    }),
}));

const ExternalLink = styled("a")(({theme}) => ({
    overflow: "hidden",
    whiteSpace: "pre",
    marginBottom: "8px",
    textDecoration: "none",
    color: theme.palette.text.primary,
}));

const StyledText = styled(Span)(({theme, compact, active}) => ({
    whiteSpace: "nowrap",
    paddingLeft: "0.8rem",
    transition: "all 0.15s ease",
    fontSize: "13px",
    fontWeight: 500,
    color: active ? theme.palette.primary.main : theme.palette.text.secondary,
    ...(compact && {
        opacity: 0,
        width: 0,
    }),
}));

const BulletIcon = styled("div")(({theme, active}) => ({
    width: 4,
    height: 4,
    marginLeft: "10px",
    marginRight: "8px",
    overflow: "hidden",
    borderRadius: "50%",
    background: active ? theme.palette.primary.main : theme.palette.text.disabled,
    boxShadow: active ? `0px 0px 0px 3px ${theme.palette.primary[200]}` : "none",
}));

const BadgeValue = styled(Box)(({compact, theme}) => ({
    color: "white",
    fontSize: "12px",
    fontWeight: 500,
    padding: "1px 6px",
    overflow: "hidden",
    borderRadius: "300px",
    backgroundColor: theme.palette.primary.main,
    display: compact ? "none" : "unset",
})); // Common icon style

const iconStyle = (active) => ({
    fontSize: 18,
    marginRight: "4px",
    color: active ? "primary.main" : "text.secondary",
});

const MultiLevelMenu = (props) => {
    const {sidebarCompact} = props;
    const navigate = useNavigate();
    const {pathname} = useLocation(); // handle active current page
    const {navigations} = useNavigation();

    const activeRoute = (path) => (pathname === path ? 1 : 0); // handle navigate to another route or page

    const handleNavigation = (path) => navigate(path); //   RECURSIVE FUNCTION TO RENDER MULTI LEVEL MENU

    const renderLevels = (data, subMenu) => {
        return data.map((item, index) => {

            const childrens = item.items ? renderLevels(item.items, false) : null
            if (item.type === "label") {
                return (
                    <Box key={index}>
                        <HasAccess asset={item.identifier} role={process.env.REACT_APP_OWNER_IDENTIFIER}>
                            <ListLabel compact={sidebarCompact}>
                                {item.label}
                            </ListLabel>
                        </HasAccess>
                        {childrens}
                    </Box>
                );
            }
            if (item.children) {
                return (
                    <HasAccess key={index} asset={item.identifier} role={process.env.REACT_APP_OWNER_IDENTIFIER}>
                        <SidebarAccordion
                            item={item}
                            sidebarCompact={sidebarCompact}
                        >
                            {renderLevels(item.children, true)}
                        </SidebarAccordion>
                    </HasAccess>
                );
            } else if (item.type === "extLink") {
                return (
                    <HasAccess key={index} asset={item.identifier} role={process.env.REACT_APP_OWNER_IDENTIFIER}>
                        <ExternalLink

                            href={item.path}
                            rel="noopener noreferrer"
                            target="_blank"
                            style={{marginLeft: subMenu ? 15 : 0}}
                        >
                            <NavItemButton key={item.name} name="child" active={0}>
                                {(() => {
                                    if (item.icon) {
                                        return <item.icon sx={iconStyle(0)}/>;
                                    } else if (item.iconify) {
                                        return (
                                            <Iconify sx={iconStyle(0)} icon={item.iconify}/>
                                        )
                                    } else {
                                        return (
                                            <span className="item-icon icon-text">{item.iconText}</span>
                                        );
                                    }
                                })()}

                                <StyledText
                                    compact={sidebarCompact}
                                    active={activeRoute(item.path)}
                                >
                                    {item.name}
                                </StyledText>

                                <Box mx="auto"/>

                                {item.badge && (
                                    <BadgeValue compact={sidebarCompact}>
                                        {item.badge.value}
                                    </BadgeValue>
                                )}
                            </NavItemButton>
                        </ExternalLink>
                    </HasAccess>
                );
            } else {
                return (
                    <HasAccess key={index} asset={item.identifier} role={process.env.REACT_APP_OWNER_IDENTIFIER}>
                        <Box>
                            <NavItemButton
                                key={item.name}
                                className="navItem"
                                active={activeRoute(item.path)}
                                onClick={() => handleNavigation(item.path)}
                                style={{marginLeft: subMenu ? 15 : 0}}
                            >

                                {item?.icon ? (
                                    <item.icon sx={iconStyle(activeRoute(item.path))}/>
                                ) : item.iconify ? (
                                    <Iconify sx={{...iconStyle(activeRoute(item.path)), minWidth: `18px`}}
                                             icon={item.iconify}/>) : (
                                    <BulletIcon active={activeRoute(item.path)}/>
                                )}

                                <StyledText
                                    compact={sidebarCompact}
                                    active={activeRoute(item.path)}
                                >
                                    {item.name}
                                </StyledText>

                                <Box mx="auto"/>

                                {item.badge && (
                                    <BadgeValue compact={sidebarCompact}>
                                        {item.badge.value}
                                    </BadgeValue>
                                )}
                            </NavItemButton>
                        </Box>
                    </HasAccess>
                );
            }
        });
    };

    console.log({navigations})

    return <>
        <PermissionsLoading>
            {renderLevels(navigations)}
        </PermissionsLoading>
    </>;
};

export default MultiLevelMenu;
