import NProgress from "nprogress";
import React, {useEffect} from "react";
import Loading from "./Loading";
import usePermissions from "../hooks/usePermissions";

const LoadingScreen = () => {

    const {permissions: {initialized}} = usePermissions()
    NProgress.configure({
        showSpinner: false,
    });
    useEffect(() => {
        NProgress.start();
        return () => {
            NProgress.done();
        };
    }, []);
    return <Loading isDashboard={false} message={initialized ? "" : "Loading your personalized environment"}/>;
};

export default LoadingScreen;
