import {useEffect, useRef, useState} from 'react';
import {
    alpha,
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardHeader,
    DialogActions,
    Divider,
    Grid,
    IconButton,
    Link,
    ListItemText,
    MenuItem,
    MenuList,
    Popover,
    Stack,
    styled,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import {greyLight} from "../../theme/colors";
import {useDispatch, useSelector} from "../../redux/store";
import useAuth from "../../hooks/useAuth";
import {
    getOrganization,
    getOrganizations,
    setActiveOrganization,
    setOrganization
} from "../../redux/slices/organization";
import {getWorkspaces, setActiveWorkspace} from "../../redux/slices/workspaces";
import {filter} from "lodash";
import {AddTwoTone, CopyAll} from "@mui/icons-material";
import FlexBox from "../../components/flexbox/FlexBox";
import Dialog from "../../components/Dialog";
import OrganizationInformation from "../../page-sections/registration/organization/OrganizationInformation";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {FormProvider} from "../../components/hook-form";
import * as Yup from "yup";
import {LoadingButton} from "@mui/lab";
import axios from "../../utils/axios";


const CardActionAreaWrapper = styled(CardActionArea)(
    ({theme}) => `
      .MuiTouchRipple-root {
        opacity: .2;
      }

      .MuiCardActionArea-focusHighlight {
        background: ${theme.palette.primary.main};
      }

      &:hover {
        .MuiCardActionArea-focusHighlight {
          opacity: .05;
        }
      }
`
);

const MenuListWrapperSecondary = styled(MenuList)(
    ({theme}) => `
  padding: ${theme.spacing(3)};
  width: 100%;
  & .MuiMenuItem-root {
      width: 100%;
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${greyLight["700"]};

      &.Mui-selected {
          background: ${theme.palette.primary.light};
          color: ${greyLight["800"]};
      };
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.palette.secondary.light};
          color: ${greyLight["800"]};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

function HeaderMenu() {
    const {t} = useTranslation();

    const dispatch = useDispatch();

    const {user} = useAuth();

    const theme = useTheme();

    const {
        workspaces,
        activeWorkspace,
    } = useSelector((state) => state.workspaces);

    const {
        activeOrganization,
        organizations,
        organization,
        organizationsCollected,
        organizationCollected,
        isLoading: loadingOrganizations
    } = useSelector((state) => state.organization);

    const ref = useRef(null);

    const [isOpen, setOpen] = useState(false);

    const [openNewOrg, setOpenNewOrg] = useState(false);

    useEffect(() => {
        if (user && user.permissions?.length > 0 && !loadingOrganizations && !organizationCollected) {
            dispatch(getOrganization(user.permissions[0].id, window.localStorage.getItem('activeOrganization')))
        }
    }, [user, dispatch, loadingOrganizations, organizationCollected]);

    useEffect(() => {
        if (user && user.permissions?.length > 0 && !loadingOrganizations && !organizationsCollected) {
            dispatch(getOrganizations(user.permissions[0].id, window.localStorage.getItem('activeOrganization')))
        }
    }, [user, dispatch, loadingOrganizations, organizationCollected, organizationsCollected])

    useEffect(() => {
        if (organizationsCollected && organizationCollected && !activeOrganization) {
            dispatch(setActiveOrganization(organization))
        }
    }, [organizationsCollected, activeOrganization, organizationCollected, organization, dispatch])

    useEffect(() => {
        if (activeOrganization) {
            axios.defaults.headers.common['x-gait55-organization'] = activeOrganization._id;
            window.localStorage.setItem('activeOrganization', activeOrganization._id)
        }
    }, [activeOrganization]);

    useEffect(() => {
        console.log({activeWorkspace})
        if (activeWorkspace) {
            axios.defaults.headers.common['x-gait55-workspace'] = activeWorkspace._id;
            window.localStorage.setItem('activeWorkspace', activeWorkspace._id);
        }
    }, [activeWorkspace])

    useEffect(() => {
        if (activeOrganization) {
            dispatch(getWorkspaces(activeOrganization._id, window.localStorage.getItem('activeWorkspace')))
        }
    }, [activeOrganization, dispatch])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const ref2 = useRef(null);
    const [isOpen2, setOpen2] = useState(false);

    const handleOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const defaultValues = {
        organization: {
            name: '',
            email: '',
            billingEmail: '',
            supportEmail: '',
            phoneNumber: '',
            phoneCountryCode: '',
            phoneCountry: '',
            phoneFriendlyName: '',
            address: '',
            addressFriendlyName: '',
            state: '',
            city: '',
            zipCode: '',
            country: '',
            avatar: '/static/illustration/crm-lead.svg',
            picture: '/static/cover/cover-6.png',
            url: '',
            facebookLink: '',
            instagramLink: '',
            youtubeLink: '',
            linkedinLink: '',
            twitterLink: '',
            about: '',
        }
    };

    const validationSchema = Yup.object().shape({
        organization: Yup.object().shape({
            name: Yup.string()
                .min(3, "First name must be greater then 3 characters")
                .required("First Name is Required!"),
            email: Yup.string()
                .email("Invalid email address")
                .required("Email is Required!"),
            phoneNumber: Yup.string().min(9, 'Phone number needs to be at least 9 numbers').required("Phone Number is required!"),
            address: Yup.string().required("Address is Required!"),
            state: Yup.string().required("State is Required!"),
            city: Yup.string().required("City is Required!"),
            zipCode: Yup.number().typeError('Zip codes needs to be a number').required("Zip Code is Required!"),
            country: Yup.string().required("Country is Required!"),
        }),
    });

    const methods = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const {
        watch,
        reset,
        handleSubmit,
        formState: {isSubmitting}
    } = methods;

    const handleAddNewOrg = () => {
        reset()
        setOpen2(false);
        setOpenNewOrg(true)
    }

    const onSubmit = async (data) => {
        try {
            console.log({data, values: watch()});

            const {data: {organizations}} = await axios.put('/api/organizations', {
                organizations: [{...data.organization, parentOrganization: activeOrganization._id}]
            })

            await axios.put('/api/workspaces', {
                workspaces: organizations.map((org) => ({
                    name: org.name,
                    organization: org._id
                }))
            });

            dispatch(getOrganizations(user.permissions[0].id, window.localStorage.getItem('activeOrganization')));

            setOpenNewOrg(false)

        } catch (e) {
        }
    }

    return (
        <>
            <Box
                sx={{
                    display: {xs: 'none', md: 'inline-flex'}
                }}
            >
                <Dialog open={openNewOrg} onClose={() => setOpenNewOrg(false)}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} style={{height: '100%'}}>
                        <OrganizationInformation/>
                        <DialogActions>
                            <LoadingButton loading={isSubmitting} variant={"outlined"}
                                           onClick={() => setOpenNewOrg(false)}>Cancel</LoadingButton>
                            <LoadingButton loading={isSubmitting} variant={"contained"}
                                           type={"submit"}>Save</LoadingButton>
                        </DialogActions>
                    </FormProvider>
                </Dialog>
                <Button
                    ref={ref}
                    onClick={handleOpen}
                    endIcon={<KeyboardArrowDownTwoToneIcon/>}
                    color="secondary"
                    size="small"
                    sx={{
                        mr: 1,
                        px: 2,
                        pt: 2,
                        pb: 1,
                        backgroundColor: `${theme.palette.secondary.light}`,
                        color: `${theme.palette.secondary.dark}`,

                        '.MuiSvgIcon-root': {
                            color: `${theme.palette.secondary.dark}`,
                            transition: `${theme.transitions.create(['color'])}`
                        },

                        '&:hover': {
                            backgroundColor: `${theme.palette.primary.main}`,
                            color: `${theme.palette.getContrastText(
                                theme.palette.primary.main
                            )}`,

                            '.MuiSvgIcon-root': {
                                color: `${theme.palette.getContrastText(
                                    theme.palette.secondary.main
                                )}`
                            }
                        }
                    }}
                >
                    <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography variant={"overline"} sx={{p: 0, mt: -2, mb: -1}}>{t('Workspace')}</Typography>
                        {activeWorkspace?.name}
                    </Stack>
                </Button>
                <Button
                    ref={ref2}
                    onClick={handleOpen2}
                    endIcon={<KeyboardArrowDownTwoToneIcon/>}
                    color="secondary"
                    size="small"
                    sx={{
                        mr: 1,
                        px: 2,
                        pt: 2,
                        pb: 1,
                        backgroundColor: `${theme.palette.secondary.light}`,
                        color: `${theme.palette.secondary.dark}`,

                        '.MuiSvgIcon-root': {
                            color: `${theme.palette.secondary.dark}`,
                            transition: `${theme.transitions.create(['color'])}`
                        },

                        '&:hover': {
                            backgroundColor: `${theme.palette.primary.main}`,
                            color: `${theme.palette.getContrastText(
                                theme.palette.primary.main
                            )}`,

                            '.MuiSvgIcon-root': {
                                color: `${theme.palette.getContrastText(
                                    theme.palette.secondary.main
                                )}`
                            }
                        }
                    }}
                >
                    <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography variant={"overline"} sx={{p: 0, mt: -2, mb: -1}}>{t('Organization')}</Typography>
                        {activeOrganization?.name}
                    </Stack>
                </Button>
            </Box>
            <Popover
                disableScrollLock
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        background: alpha(greyLight["800"], 0.06)
                    }}
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="space-between"
                >
                    <Box>
                        <Typography
                            sx={{
                                pb: 0.5
                            }}
                            variant="h4"
                        >
                            {t('Workspaces')}
                        </Typography>
                        <Typography noWrap variant="subtitle2">
                            {t('All currently active workspaces')}
                        </Typography>
                    </Box>
                    <Link
                        href="#"
                        variant="subtitle2"
                        sx={{
                            textTransform: 'none',
                            ml: 3,
                            display: 'inline-block'
                        }}
                    >
                        {t('View all')}
                    </Link>
                </Box>
                <Divider/>
                <Stack
                    direction={{xs: 'column', sm: 'row'}}
                    divider={<Divider orientation="vertical" flexItem/>}
                    justifyContent="stretch"
                    alignItems="stretch"
                    spacing={0}
                >
                    <MenuListWrapperSecondary disablePadding>
                        {workspaces.map((workspace) => (
                            <Stack key={workspace?._id} direction={"row"} sx={{width: '100%'}}>
                                <MenuItem selected={workspace?._id === activeWorkspace?._id}
                                          onClick={() => {
                                              dispatch(setActiveWorkspace(workspace))
                                          }}>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            variant: 'h5'
                                        }}
                                        primary={workspace.name}
                                    />
                                    <ChevronRightTwoToneIcon
                                        sx={{
                                            ml: 2,
                                            color: workspace?._id === activeWorkspace?._id ? '' : `${greyLight["300"]}`,
                                            opacity: 0.8
                                        }}
                                    />
                                </MenuItem>
                                <FlexBox flexGrow={1}/>
                                <Tooltip title={t("Copy Id")}>
                                    <IconButton onClick={() => navigator.clipboard.writeText(activeWorkspace?._id)}>
                                        <CopyAll/>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        ))}
                    </MenuListWrapperSecondary>
                </Stack>
            </Popover>
            <Popover
                disableScrollLock
                anchorEl={ref2.current}
                onClose={handleClose2}
                open={isOpen2}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        background: 'linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)'
                    }
                }}
            >
                <Box
                    sx={{
                        maxWidth: 400,
                        position: 'relative'
                    }}
                    p={3}
                >
                    <Typography
                        variant="h4"
                        gutterBottom
                        textAlign="center"
                        sx={{
                            color: "#ffffff",
                            fontSize: theme.typography.pxToRem(18)
                        }}
                    >
                        {t('Organizations')}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        textAlign="center"
                        sx={{
                            color: "#ffffff"
                        }}
                    >
                        {t("All sub-organizations you have access to")}
                    </Typography>
                    <IconButton sx={{position: 'absolute', top: 0, right: 0}} onClick={handleAddNewOrg}>
                        <AddTwoTone sx={{color: '#fff'}}/>
                    </IconButton>
                    <Grid container mt={1} spacing={2}>
                        {filter(organizations, (org) => org?._id !== activeOrganization?._id).map((org) => (
                            <Grid key={org._id} item xs={12} sm={6}>
                                <Card>
                                    <CardHeader action={<Tooltip title={t("Copy Id")}>
                                        <IconButton
                                            sx={{zIndex: theme => theme.zIndex.appBar + 30}}
                                            onClick={() => navigator.clipboard.writeText(org?._id)}>
                                            <CopyAll/>
                                        </IconButton>
                                    </Tooltip>}/>
                                    <CardActionAreaWrapper
                                        sx={{
                                            p: 2, mt: -6
                                        }}
                                        onClick={() => {
                                            dispatch(setOrganization(org));
                                            handleClose2();
                                        }}
                                    >
                                        <Avatar src={org.avatar}/>
                                        <Typography variant="subtitle1">{org.name}</Typography>
                                    </CardActionAreaWrapper>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Popover>
        </>
    );
}

export default HeaderMenu;
