import useLocalStorage from "hooks/useLocalStorage";
import {createContext, useCallback, useEffect} from "react";
import {THEMES} from "../constants";

const initialSettings = {
    activeLayout: "layout3",
    direction: "ltr",
    theme: THEMES.LIGHT,
    responsiveFontSizes: true,
};
export const SettingsContext = createContext({
    settings: initialSettings,
    saveSettings: (arg) => {
    },
}); // component props type

const SettingsProvider = ({children}) => {

    const {data: settings, storeData: setStoreSettings} = useLocalStorage(
        "settings",
        initialSettings
    );

    const saveSettings = useCallback((updateSettings) => {
        setStoreSettings(updateSettings);
    }, [setStoreSettings])

    const onModeChange = useCallback((event) => {
        const isDarkMode = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        saveSettings({...settings, theme: isDarkMode() ? THEMES.DARK : THEMES.LIGHT});
    }, [saveSettings, settings])

    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', onModeChange)
        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', onModeChange)
        }
    }, [onModeChange])

    return (
        <SettingsContext.Provider
            value={{
                settings,
                saveSettings,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsProvider;
