import numeral from 'numeral';
import {parsePhoneNumber} from 'awesome-phonenumber'

// ----------------------------------------------------------------------

export function fCurrency(number) {
    return numeral(number).format(Number.isInteger(number) ? '$0,0.00' : '$0,0.00');
}

export function fPhone(number, iso) {
    if (!number) {
        return number;
    }

    const pn = parsePhoneNumber(number, iso || 'us');
    return pn.getNumber('international');
}

export function fPercent(number) {
    return numeral(number / 100).format('0.0%');
}

export function fNumber(number, decimal) {
    return numeral(number).format(decimal ? '0.00' : '');
}

export function fShortenNumber(number) {
    return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
    return numeral(number).format('0.0 b');
}
