import {createContext, useEffect, useReducer} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import PropTypes from 'prop-types';
import axios from '../utils/axios';
import {camelCase} from 'lodash';
import {useNavigate} from "react-router";
import {PATH_DASHBOARD} from "../router/paths";


const initialAuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
};

const handlers = {
    INITIALIZE: (state, action) => {
        const {isAuthenticated, user} = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user
        };
    },
    LOGIN: (state, action) => {
        const {user} = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null
    })
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
    ...initialAuthState,
    method: 'Auth0',
    logInWith: () => Promise.resolve(),
    refreshUser: () => Promise.resolve(),
    loginWithRedirect: () => Promise.resolve(),
    logout: () => Promise.resolve()
});

export const AuthProvider = (props) => {
    const {children} = props;
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    const navigate = useNavigate();
    const {
        loginWithRedirect,
        logout: auth0Logout,
        handleRedirectCallback,
        isAuthenticated,
        getAccessTokenSilently,
        getIdTokenClaims,
        user,
        isLoading
    } = useAuth0()
    // dErAcWH9ay2mjudII1KY5nBB2WTeXZz9
    // 9Vp76hjSMfnW1dd3EaEVrZL6a8bqHKYRRN0USmNxBXojJUReXcmjRPaZJONlfV0p

    useEffect(() => {
        const initialize = async () => {
            try {

                if (isLoading) {
                    return;
                }

                const query = window.location.search;

                if (query.includes('code=') && query.includes('state=')) {
                    try {
                        await handleRedirectCallback();
                    } catch (e) {
                        console.error('handleRedirectCallback error', e);
                    }
                    window.history.replaceState({}, document.title, '/');
                }

                if (isAuthenticated) {
                    const pathRedirect = window.localStorage.getItem('requestedLocation');

                    const accessToken = await getAccessTokenSilently();

                    const idToken = await getIdTokenClaims();

                    axios.defaults.headers.common['x-gait55-authentication'] = accessToken;
                    axios.defaults.headers.common['x-gait55-authorization'] = idToken.__raw;

                    let organization = null;
                    let gait55User = null;

                    try {
                        const accountResponse = await axios.get(`/api/organizations`, {
                            params: {
                                userId: user.sub
                            }
                        });

                        const {
                            data: {organizations = [], users = []}
                        } = accountResponse;
                        organization = organizations[0] || null;
                        gait55User = users[0] || null;
                    } catch (e) {
                        console.error(e)
                    }

                    if (gait55User?._id) {
                        axios.post('/api/users', {users: [{_id: gait55User._id, lastLogin: new Date()}]})
                    }

                    axios.defaults.headers.common['x-gait55-organization'] = organization._id;

                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated,
                            user: Object.assign(
                                {
                                    id: user.sub,
                                    avatar: user.picture,
                                    email: user.email,
                                    name: user.name,
                                    role: user.role,
                                    location: user.location,
                                    username: user.username,
                                    posts: user.posts,
                                    coverImg: user.coverImg,
                                    followers: user.followers,
                                    description: user.description,
                                    ...gait55User
                                },
                                ...Object.keys(user).map((key) => ({
                                    [camelCase(key)]: user[key]
                                }))
                            ),
                            organization
                        }
                    });

                    if (!organization) {
                        navigate(PATH_DASHBOARD.register.root);
                    }

                    if (pathRedirect) {
                        window.localStorage.removeItem('requestedLocation');
                        navigate(pathRedirect);
                    }

                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated,
                            user: null
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        };

        initialize();
    }, [navigate, getAccessTokenSilently, getIdTokenClaims, handleRedirectCallback, isAuthenticated, user, isLoading]);

    const refreshUser = async () => {
        if (isAuthenticated) {

            const accessToken = await getAccessTokenSilently();

            const idToken = await getIdTokenClaims();

            axios.defaults.headers.common['x-gait55-authentication'] = accessToken;
            axios.defaults.headers.common['x-gait55-authorization'] = idToken.__raw;

            let organization = null;
            let gait55User = null;

            try {
                const accountResponse = await axios.get(`/api/organizations`, {
                    params: {
                        userId: user.sub
                    }
                });

                const {
                    data: {organizations = [], users = []}
                } = accountResponse;
                organization = organizations[0] || null;
                gait55User = users[0] || null;
            } catch (e) {
                console.error(e)
            }

            dispatch({
                type: 'INITIALIZE',
                payload: {
                    isAuthenticated,
                    user: Object.assign(
                        {
                            id: user.sub,
                            avatar: user.picture,
                            email: user.email,
                            name: user.name,
                            role: user.role,
                            location: user.location,
                            username: user.username,
                            posts: user.posts,
                            coverImg: user.coverImg,
                            followers: user.followers,
                            description: user.description,
                            ...gait55User
                        },
                        ...Object.keys(user).map((key) => ({
                            [camelCase(key)]: user[key]
                        }))
                    ),
                    organization
                }
            });

        } else {
            dispatch({
                type: 'INITIALIZE',
                payload: {
                    isAuthenticated,
                    user: null
                }
            });
        }
    }

    const logout = () => {
        dispatch({
            type: 'LOGOUT'
        });

        axios.defaults.headers.common['x-gait55-authentication'] = null;
        axios.defaults.headers.common['x-gait55-authorization'] = null;

        auth0Logout({returnTo: process.env.REACT_APP_WEBSITE_URL});
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'Auth0',
                loginWithRedirect,
                refreshUser,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default AuthContext;
