import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {AuthProvider} from "contexts/Auth0Context";
import "nprogress/nprogress.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import React from "react";
import ReactDOM from "react-dom";
import "react-image-lightbox/style.css";
import "react-quill/dist/quill.snow.css";
import {BrowserRouter} from "react-router-dom";
import "simplebar/dist/simplebar.min.css";
import App from "./App";
import SettingsProvider from "./contexts/settingsContext";
import reportWebVitals from "./reportWebVitals";
import "./__fakeData__";
import {HelmetProvider} from "react-helmet-async";
import {auth0Config, gait55Config} from "./config";
import {Auth0Provider} from "@auth0/auth0-react";
import {SnackbarProvider} from "notistack";
import {Provider as ReduxProvider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
// redux
import {persistor, store} from './redux/store';
import PermissionsProvider from "./contexts/PermissionsContext";
import {keys, last} from "lodash";

const getIntegrationResults = () => {
    const hash = last(window.location.hash.split('#')) || window.location.hash;
    const search = new URLSearchParams(hash);
    const params = Object.fromEntries(search.entries());
    console.log({hash, search: search.entries(), params, keys: keys(search.entries())});
    if (hash && window.location.pathname.includes('/integrations/joltcrm')) {
        window.localStorage.setItem('JoltCRMAuth', JSON.stringify(params));
    }
};

getIntegrationResults();

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
            <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Auth0Provider {...auth0Config}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <SettingsProvider>
                                <BrowserRouter>
                                    <AuthProvider>
                                        <PermissionsProvider {...gait55Config}>
                                            <SnackbarProvider
                                                anchorOrigin={{horizontal: "center", vertical: "top"}}
                                                maxSnack={3}
                                                preventDuplicate
                                                autoHideDuration={3000}
                                            >
                                                <App/>
                                            </SnackbarProvider>
                                        </PermissionsProvider>
                                    </AuthProvider>
                                </BrowserRouter>
                            </SettingsProvider>
                        </LocalizationProvider>
                    </Auth0Provider>
                </PersistGate>
            </ReduxProvider>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById("root")
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
