import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    types: [],
    typesCollected: false
};

const slice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ROLES
        getRolesSuccess(state, action) {
            state.isLoading = false;
            state.typesCollected = true;
            state.types = action.payload;
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getContentTypes(workspace, organization) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/content/type', {params: {workspace, organization}});
            dispatch(slice.actions.getRolesSuccess(response.data.content));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
